import { Card, Col, Radio, Row } from 'antd'
import React, { useEffect, useState } from 'react'
import Breadcrumbs from '../components/Breadcrumb/breadcrumb'
import TrackRecordChart from '../components/TrackRecord/trackRecordChart';
import { getDailyStatsData, getTradesHistory } from '../api/api';
const toDate = new Date();
const comingDate = new Date(toDate);
comingDate.setDate(toDate.getDate() + 1);

// Format dates to YYYY-MM-DD using template literals for better readability
const formatDate = (date) =>
    `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

const currentYearStart = new Date(toDate.getFullYear(), 0, 1); // January 1st of the current year
const currentYearEnd = comingDate;


const TrackRecord = () => {
    const [selectedFilter, setSelectedFilter] = useState("yearly");
    const [statsData, setStatsData] = useState([]);
    const [data, setData] = useState([]);
    const [selectedDate, setSelectedDate] = useState({
        fromDate: formatDate(currentYearStart),
        toDate: formatDate(currentYearEnd),
    });

    useEffect(() => {
        fetchDailyStats()
        fetchTrades()
    }, [selectedDate])
    const refreshDataCallback = () => {
        fetchDailyStats()
        fetchTrades()
    }

    const fetchDailyStats = () => {
        const loginId = localStorage.getItem('loginId');
        if (!loginId) return;

        getDailyStatsData(loginId, selectedDate.fromDate, selectedDate.toDate)
            .then((res) => {
                const dailyStatsData = res.data;
                if (Array.isArray(dailyStatsData) && dailyStatsData.length) {
                    setStatsData(dailyStatsData);
                } else {
                    setStatsData([]);
                }
            })
            .catch((err) => console.error(err));

    }


    const fetchTrades = () => {
        const loginId = localStorage.getItem('loginId');
        if (!loginId) return;

        getTradesHistory(loginId, selectedDate.fromDate, selectedDate.toDate)
            .then((res) => {


                setData(res?.data || []);
            })
            .catch((err) => console.error(err));
    };


    const handleDateFilterChange = (e) => {
        const filterValue = e.target.value;
        setSelectedFilter(filterValue);

        const toDate = new Date();
        let fromDate = new Date(toDate);

        switch (filterValue) {
            case "yearly":
                // Set fromDate to January 1st of the current year
                fromDate = new Date(toDate.getFullYear(), 0, 1);
                break;
            case "monthly":
                // Set fromDate to the first day of the current month
                fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
                break;
            case "weekly":
                // Set fromDate to the most recent Monday
                const dayOfWeek = toDate.getDay(); // 0 (Sunday) - 6 (Saturday)
                const offset = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate offset for Monday
                fromDate.setDate(toDate.getDate() - offset);
                break;
            case "daily":
                // Set fromDate to the current day (same as toDate)
                fromDate = new Date(toDate);
                toDate.setDate(toDate.getDate() + 1);
                break;
            default:
                fromDate = new Date(toDate);
        }
        toDate.setDate(toDate.getDate() + 1);
        const formattedFromDate = formatDate(fromDate);
        const formattedToDate = formatDate(toDate);

        setSelectedDate({ fromDate: formattedFromDate, toDate: formattedToDate });

        // const updatedDrawdown = calculateDrawdown(statsData, filterValue);
        // setDrawdown(updatedDrawdown);
    };
    return (
        <>
            <div className="layout-content">
                <Row gutter={[24, 0]}>
                    <Col span={24} md={12}>
                        <Breadcrumbs />
                    </Col>
                    <Col span={24} md={12}>
                        <div className="preview-date-filter" style={{ display: "flex", justifyContent: "end", height: "100%", alignItems: "center" }}>
                            <Radio.Group
                                value={selectedFilter}
                                onChange={handleDateFilterChange}
                                className="trade-history-radio-btn"
                                style={{
                                    marginRight: "20px",
                                    background: "#f9f9f9",
                                    padding: "2px",
                                    borderRadius: "10px",
                                    fontWeight: "600",
                                    color: "#F9F9F9",
                                    border: "1px solid #D3D3DB",
                                }}
                            >
                                <Radio.Button value="yearly">
                                    1 Year
                                </Radio.Button>
                                <Radio.Button value="monthly">
                                    1 Month
                                </Radio.Button>
                                <Radio.Button value="weekly">
                                    1 Week
                                </Radio.Button>
                                <Radio.Button value="daily">
                                    1 Day
                                </Radio.Button>
                            </Radio.Group>
                        </div>
                    </Col>
                </Row>
                <Row gutter={[24, 0]}>
                    <Col xs={24}>
                        <Card>
                            <TrackRecordChart trades={data} statsData={statsData} selectedFilter={selectedFilter} refreshDataCallback={refreshDataCallback} />
                        </Card>
                    </Col>
                </Row>
            </div>
        </>
    )
}

export default TrackRecord