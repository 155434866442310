import {
  Card,
  Col,
  Radio,
  Row,
  Typography
} from "antd";
import { useEffect, useState } from "react";
import { getOverallStatsData, getTradesHistory } from "../api/api";
import Breadcrumbs from "../components/Breadcrumb/breadcrumb";
import AssetProfitability from "../components/HomePro/AssetProfitability/assetProfitability";
import Orders from "../components/HomePro/Orders/orders";
import TradeIntelligence from "../components/HomePro/TradeIntelligence/tradeIntelligence";
import TradeMetricsCards from "../components/HomePro/TradeMetricsCards/tradeMetricsCards";
import TradesPlus from "../components/HomePro/TradesPlus/tradesPlus";
import RecentTradesCard from "../components/RecentTradesCard/recentTrades";
import Loading from "../components/Loading/loading";
import { calMonthlyGain } from "../utils/utils";

const toDate = new Date();
const comingDate = new Date(toDate);
comingDate.setDate(toDate.getDate() + 1);

// Format dates to YYYY-MM-DD using template literals for better readability
const formatDate = (date) =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

const currentYearStart = new Date(toDate.getFullYear(), 0, 1); // January 1st of the current year
const currentYearEnd = comingDate;

function HomePro() {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [tradesData, setTradesData] = useState([]);
  const [overallStatsData, setOverallStatsData] = useState({});
  const [monthlyGain, setMonthlyGain] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("yearly");
  const [loginId, setLoginId] = useState(localStorage.getItem('loginId'));
  const [period, setPeriod] = useState('yearly');
  const [selectedDate, setSelectedDate] = useState({
    fromDate: formatDate(currentYearStart),
    toDate: formatDate(currentYearEnd),
  });

  useEffect(() => {
    // Set up an interval to periodically check for changes in userType from localStorage
    const interval = setInterval(() => {
      const storedUser = localStorage.getItem('userId');
      if (storedUser !== loginId) {
        setLoginId(storedUser)
      }
    }, 800); // Check every 800ms

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [loginId]);
  useEffect(() => {
    const fetchDataAndBalance = () => {
      fetchData();
      fetchTradeData();
    };
    fetchDataAndBalance();

    const intervalID = setInterval(fetchDataAndBalance, 5000);

    return () => clearInterval(intervalID);
  }, [selectedDate]);

  const fetchData = () => {
    const loginId = localStorage.getItem('loginId');
    if (!loginId) return;

    getOverallStatsData(loginId, period)
      .then((res) => {
        if (res.data.id) {
          setOverallStatsData(res.data)
        } else setOverallStatsData({})
      })
      .catch((err) => console.error(err));

  };

  const fetchTradeData = () => {
    const loginId = localStorage.getItem('loginId');
    if (!loginId) return;

    getTradesHistory(loginId, selectedDate.fromDate, selectedDate.toDate)
      .then((res) => {
        const recentTrades = res?.data?.slice(-4)?.filter((item) => item.symbol)?.map((trade) => ({
          title: trade.type === "BUY" ? "Buy" : "Sell",
          time: new Date(trade.openTime).toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          color: trade.type === "BUY" ? "#00cd88" : "#f94b60",
          symbol: trade.symbol,
          price: trade.pal.toFixed(2),
        }));
        if (selectedFilter === 'yearly') {
          const gain = calMonthlyGain(res?.data)
          setMonthlyGain(gain || 0)
        }
        setData(recentTrades || []);
        if (res?.data) {
          setTradesData(res?.data)
        } else setTradesData(res?.data)
      })
      .catch((err) => console.error(err));
  };

  const handleDateFilterChange = (e) => {
    const filterValue = e.target.value;
    setSelectedFilter(filterValue);

    const toDate = new Date();
    let fromDate = new Date(toDate);

    switch (filterValue) {
      case "yearly":
        // Set fromDate to January 1st of the current year
        fromDate = new Date(toDate.getFullYear(), 0, 1);
        setPeriod("yearly")
        break;
      case "monthly":
        // Set fromDate to the first day of the current month
        fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
        setPeriod("monthly")
        break;
      case "weekly":
        // Set fromDate to the most recent Monday
        const dayOfWeek = toDate.getDay(); // 0 (Sunday) - 6 (Saturday)
        const offset = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate offset for Monday
        fromDate.setDate(toDate.getDate() - offset);
        setPeriod("weekly")
        break;
      case "daily":
        // Set fromDate to the current day (same as toDate)
        fromDate = new Date(toDate);
        toDate.setDate(toDate.getDate() + 1);
        setPeriod("daily")
        break;
      default:
        setPeriod("yearly")
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);

    setSelectedDate({ fromDate: formattedFromDate, toDate: formattedToDate });

  };
  return (loginId ?
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col span={24} md={12}>
            <Breadcrumbs />
          </Col>
          <Col span={24} md={12}>
            <div className="preview-date-filter" style={{ display: "flex", justifyContent: "end", height: "100%", alignItems: "center" }}>
              <Radio.Group
                value={selectedFilter}
                onChange={handleDateFilterChange}
                className="trade-history-radio-btn"
                style={{
                  marginRight: "20px",
                  background: "#f9f9f9",
                  padding: "2px",
                  borderRadius: "10px",
                  fontWeight: "600",
                  color: "#F9F9F9",
                  border: "1px solid #D3D3DB",
                }}
              >
                <Radio.Button value="yearly">
                  1 Year
                </Radio.Button>
                <Radio.Button value="monthly">
                  1 Month
                </Radio.Button>
                <Radio.Button value="weekly">
                  1 Week
                </Radio.Button>
                <Radio.Button value="daily">
                  1 Day
                </Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <Row className="rowgap-vbox" gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox card-content">
              <Orders overallStatsData={overallStatsData} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox card-content">
              <TradesPlus tradesData={tradesData} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={8} xl={8} className="mb-24">
            <Card bordered={false} className="criclebox card-content">
              <AssetProfitability overallStatsData={overallStatsData} />
            </Card>
          </Col>
        </Row >
        <Row gutter={[24, 0]}>
          <Col xs={24} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <TradeMetricsCards overallStatsData={overallStatsData} />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={15} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <h3 style={{ margin: "0", marginLeft: "12px" }}>TradeIntelligence</h3>
              <Row gutter={[24, 0]}>
                <Col xs={15}>
                  <TradeIntelligence />
                </Col>
                <Col xs={9} style={{ display: "flex", justifyContent: "right", paddingRight: "25px" }}>
                  <RecentTradesCard data={data} useFor="homePro" monthlyGain={monthlyGain} />
                </Col>
              </Row>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={9} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="linechart">
                <Title level={5} style={{ fontWeight: "bold" }}>Stress Score</Title>

              </div>

              {/* Blur Overlay */}
              <div className="blur-overlay">
                <div className="blur-overlay-text">Coming Soon</div>
              </div>
            </Card>
          </Col>
        </Row>

      </div >
    </> : <Loading />
  );
}

export default HomePro;
