import 'resize-observer-polyfill';
import { Switch, Route, Redirect } from "react-router-dom";
import ProtectedRoute from "./components/ProtectedRoute";
import Home from "./pages/Home";
import TradesHistory from "./components/TradeHistory/tradesHistory";
import Tables from "./pages/Tables";
import Billing from "./pages/Billing";
import Rtl from "./pages/Rtl";
import Profile from "./pages/Profile";
import SignUp from "./pages/SignUp";
import SignIn from "./pages/SignIn";
import "./assets/styles/main.css";
import "./assets/styles/responsive.css";
import VerifyEmail from "./pages/VerifyEmail";
import EmailExpire from "./pages/EmailExpire";
import Reports from './pages/Reports';
import HomePro from './pages/HomePro';
import TrackRecord from './pages/TrackRecord';
import { useEffect, useState } from 'react';
import TrackRecordPro from './pages/TrackRecordPro';
import { Spin } from 'antd';
import Loading from './components/Loading/loading';

function App() {
  // Initialize userType state with the value from localStorage
  const [userType, setUserType] = useState(() => localStorage.getItem('userType'));
  const [userId, setUserId] = useState(() => localStorage.getItem('userId'));


  useEffect(() => {
    // Set up an interval to periodically check for changes in userType from localStorage
    const interval = setInterval(() => {
      const storedUserType = localStorage.getItem('userType');
      const storedUser = localStorage.getItem('userId');
      if (storedUserType !== userType) {
        setUserType(storedUserType); // Update userType if it has changed
        setUserId(storedUser)
      }
    }, 800); // Check every 800ms

    // Cleanup the interval on component unmount
    return () => clearInterval(interval);
  }, [userType, userId]);


  // Define the protected routes with components depending on userType
  const protectedRoutes = [
    // Different components are rendered based on userType
    { path: "/dashboard", component: userType => (+userType === 1 ? HomePro : userType === null ? Loading : Home), exact: true },
    { path: "/performance-overview", component: userType => (+userType === 1 ? HomePro : Home), exact: true },
    { path: "/track-record", component: userType => (+userType === 1 ? TrackRecordPro : TrackRecord) },
    // Components that are common for all user types
    { path: "/trade-report", component: () => Reports },
    { path: "/trade-history", component: () => TradesHistory },
    { path: "/tables", component: () => Tables, exact: true },
    { path: "/billing", component: () => Billing, exact: true },
    { path: "/rtl", component: () => Rtl, exact: true },
    { path: "/profile", component: () => Profile, exact: true },
  ];

  // Function to render protected routes dynamically based on userType
  function renderProtectedRoutes(userType) {
    return protectedRoutes.map(({ path, component, exact }, index) => (
      <ProtectedRoute
        key={index}
        path={path}
        component={typeof component === "function" ? component(userType) : component}
        exact={!!exact} // Ensure exact is a boolean
      />
    ));
  }

  return (
    <div className="App">
      <Switch>
        {/* Routes that do not depend on userType */}
        <Route path="/sign-up" exact component={SignUp} />
        <Route path="/sign-in" exact component={SignIn} />
        <Route path="/verify-email" component={VerifyEmail} />
        <Route path="/email-expire" component={EmailExpire} />

        {/* Render protected routes based on userType */}
        {renderProtectedRoutes(userType)}

        {/* Redirect to dashboard by default */}
        <Redirect from="*" to="/dashboard" />
      </Switch>
    </div>
  );
}

export default App;
