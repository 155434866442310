export const customTitles = {
    closePrice: "Exit(Price)",
    openTime: "Open Date",
    symbol: "Symbol",
    outcome: "Outcome",
    ticket: "Ticket",
    type: "Type",
    closeTime: "Close Date",
    openPrice: "Entry(Price)",
    gainPercentage: "Gain",
    lots: "Lots",
    stopLoss: "Stop Loss",
    takeProfit: "Take Profit",
    pal: "Profit & Loss",
    swap: "Swap",
    commission: "Commission",
    magic: "Magic",
    drawdown: "Drawdown",
    comments: "Comments",
    pips: "Pips",
};

export const columnsOption = [
      { title: "Close Date", key: "closeTime" },
      { title: "Outcome", key: "outcome" },
      { title: "Comments", key: "comments" },
      { title: "Open Date", key: "openTime" },
      { title: "Commission", key: "commission" },
      { title: "Profit & Loss", key: "pal" },
      { title: "Entry(Price)", key: "openPrice" },
      { title: "Stop Loss", key: "stopLoss" },
      { title: "Exit(Price)", key: "closePrice" },
      { title: "Swap", key: "swap" },
      { title: "Gain", key: "gainPercentage" },
      { title: "Symbol", key: "symbol" },
      { title: "Lots", key: "lots" },
      { title: "Take Profit", key: "takeProfit" },
      { title: "Magic", key: "magic" },
      { title: "Ticket", key: "ticket" },
      { title: "Type", key: "type" },
      { title: "Drawdown", key: "drawdown" },
      { title: "Pips", key: "pips" }
    ];

// Default column keys for the "Default" selection
export const defaultColumns = [
    "openTime",
    "outcome",
    "closeTime",
    "ticket",
    "symbol",
    "type",
    "openPrice",
    "closePrice",
    "gainPercentage",
    "lots",
];

