import { Col, Row, Typography } from "antd";
import dayjs from 'dayjs';
import isBetween from "dayjs/plugin/isBetween";
import ReactApexChart from "react-apexcharts";
import { useMemo, useState } from "react";
import { calculateDrawdown, dailyStatsDataFilter } from "../../utils/utils";

dayjs.extend(isBetween);

function TrackRecordChartPro({ trades, statsData, selectedFilter }) {
    const { Title } = Typography;

    const accountBalance = localStorage.getItem("accountBalance");
    const accountType = localStorage.getItem("accountType");



    // Memoized calculations and data
    const totalGainPercentage = useMemo(
        () =>
            trades
                .filter((trade) => trade.openTime && trade.closeTime && trade.gainPercentage)
                .reduce((sum, trade) => sum + trade.gainPercentage, 0),
        [trades]
    );

    const maxDrawdown = useMemo(() => calculateDrawdown(statsData), [statsData]);

    const totalPaL = useMemo(
        () =>
            statsData
                .filter(Boolean)
                .reduce((sum, item) => sum + item.pal, 0),
        [statsData]
    );

    const totalProfitFactor = useMemo(
        () =>
            statsData
                .filter(Boolean)
                .reduce((sum, item) => sum + item.profitFactor, 0),
        [statsData, selectedFilter]
    );
    const deposit = useMemo(
        () =>
            trades
                .filter((trade) => trade.symbol === null && trade.pal > 0)
                .reduce((sum, trade) => sum + trade.pal, 0),
        [trades, selectedFilter]
    );

    const withdraw = useMemo(
        () =>
            trades
                .filter((trade) => trade.symbol === null && trade.pal < 0)
                .reduce((sum, trade) => sum + trade.pal, 0),
        [trades, selectedFilter]
    );

    const items = useMemo(
        () => [
            { Title: totalGainPercentage === 0 ? 0 : totalGainPercentage.toFixed(3), user: "Gain %" },
            { Title: Math.round(totalPaL), user: "Total Profit" },
            { Title: Math.abs(maxDrawdown), user: "Max Drawdown" },
            { Title: Math.round(totalProfitFactor), user: "Profit Factor" },
            { Title: Math.round(+accountBalance || 0), user: "Balance" },
            { Title: "--", user: "Avg Risk to Reward" },
            { Title: deposit, user: "Deposit" },
            { Title: withdraw ? Math.round(Math.abs(withdraw)) : '--', user: "Withdraw" },
        ],
        [totalGainPercentage, maxDrawdown, totalPaL, deposit]
    );


    const { sortedKeys: xAxisCategories, data: palValues } = useMemo(
        () => dailyStatsDataFilter(statsData, selectedFilter),
        [statsData, selectedFilter]
    );

    const eChart = useMemo(() => {
        const maxValue = Math.max(...palValues);
        const minValue = Math.min(...palValues);
        let stepSize;
    
        if (maxValue <= 10 && minValue >= -10) {
            stepSize = 1;
        } else if (maxValue <= 30 && minValue >= -30) {
            stepSize = 5;
        } else if (maxValue <= 100 && minValue >= -100) {
            stepSize = 10;
        } else if (maxValue <= 500 && minValue >= -500) {
            stepSize = 50;
        } else if (maxValue <= 1000 && minValue >= -1000) {
            stepSize = 100;
        } else {
            stepSize = Math.ceil(Math.max(maxValue, Math.abs(minValue)) / 10);
        }
    
        return {
            series: [
                {
                    name: "PAL",
                    data: palValues.map((val) => parseFloat(val.toFixed(3))),
                    color: "#5351F1",
                },
            ],
            options: {
                chart: { type: "bar", toolbar: { show: false } },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: selectedFilter === "monthly" ? "10" : "20",
                        borderRadius: 5,
                    },
                },
                fill: { type: "solid", opacity: 1 },
                dataLabels: { enabled: false },
                stroke: { width: 1, colors: ["transparent"] },
                grid: { borderColor: "#ccc", strokeDashArray: 2 },
                xaxis: {
                    categories: xAxisCategories,
                    labels: {
                        style: {
                            colors: Array(xAxisCategories.length).fill("#667085"),
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: { colors: Array(10).fill("#667085") },
                        formatter: (val) => `${Math.round(val)}%`,
                    },
                    tickAmount: Math.ceil((maxValue - minValue) / stepSize),
                    min: Math.floor(minValue / stepSize) * stepSize,
                    max: Math.ceil(maxValue / stepSize) * stepSize,
                },
                tooltip: {
                    y: {
                        formatter: (val) => `${val}%`,
                    },
                },
            },
        };
    }, [palValues, selectedFilter, xAxisCategories]);
    

    const displayText = useMemo(() => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, "0");
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const year = String(currentDate.getFullYear()).slice(-2);

        switch (selectedFilter) {
            case "yearly":
                return `Yearly Data - ${currentDate.getFullYear()}`;
            case "monthly":
                return `Monthly Data - ${currentDate.toLocaleString("default", { month: "short" })}-${currentDate.getFullYear()}`;
            case "weekly":
                return `Weekly Data - ${month}-${year}`;
            case "daily":
                return `Day Data - ${day}-${month}-${year}`;
            default:
                return "No Data Selected";
        }
    }, [selectedFilter]);
    return (
        <>
            <div className="linechart" style={{ flexWrap: "wrap" }}>
                <div>
                    <Title style={{ margin: "0" }} level={4}>Track Record</Title>
                    <p className="lastweek">{displayText}</p>
                </div>
                <div className="account-detail-container" style={{ marginTop: "0" }}>
                    <div className="account-detail-box">
                        {/* <div>
                            <div className="acc-type-head">Type</div>
                            <div className="acc-type-text">Live (GBP)</div>
                        </div>
                        <div className="side-line"></div> */}
                    </div>

                    {/* <div className="account-detail-box m-left">
                        <div>
                            <div className="acc-type-head">Broker</div>
                            <div className="acc-type-text">IC Markets</div>
                        </div>
                        <div className="side-line"></div>
                    </div> */}

                    <div className="account-detail-box m-left">
                        <div>
                            <div className="acc-type-head">Trading</div>
                            <div className="acc-type-text">Manual</div>
                        </div>
                        <div className="side-line"></div>
                    </div>

                    <div className="account-detail-box m-left">
                        <div>
                            <div className="acc-type-head">Platform</div>
                            <div className="acc-type-text">Meta Trader4</div>
                        </div>
                        <div className="side-line"></div>
                    </div>
                </div>
            </div>

            <div id="chart">
                <ReactApexChart className="bar-chart" options={eChart.options} series={eChart.series} type="bar" height={300} />
            </div>
            <div className="chart-vistior">
                <Row gutter={[24, 10]}>
                    {items.map((v, index) => (
                        <Col xs={12} xl={6} sm={12} md={6} lg={6} key={index}>
                            <div className="chart-visitor-count">
                                <span>{v.user}</span>
                                <Title level={4}>{v.Title}</Title>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>

        </>
    );
}

export default TrackRecordChartPro;
