import React, { useEffect, useState, useCallback, useMemo, Suspense } from "react";
import { Col, Row, Spin } from "antd";
import { getDailyStatsData } from "../../../api/api";

// Lazy load components
const OverviewTable = React.lazy(() => import("./overviewTable"));
const NetPaL = React.lazy(() => import("../netPaL"));
// const NetPaLCumulative = React.lazy(() => import("../netPaLCumulative"));
const NetPaLCumulative = React.lazy(() => import("../netPalCumulative"));
const TradingOverview = ({ dateRange }) => {
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const loginId = useMemo(() => localStorage.getItem("loginId"), []);

  const fetchDailyStats = useCallback(() => {
    const controller = new AbortController();
    const signal = controller.signal;

    (async () => {
      try {
        setLoading(true);
        
        const response = await getDailyStatsData(loginId, dateRange.fromDate, dateRange.toDate, { signal });
        setData((prevData) => (JSON.stringify(prevData) !== JSON.stringify(response.data) ? response.data : prevData));
        setError(null);
      } catch (err) {
        if (err.name !== "AbortError") {
          console.error(err);
          setError("Failed to fetch daily stats data");
        }
      } finally {
        setLoading(false);
      }
    })();

    return () => controller.abort();
  }, [loginId, dateRange]);

  useEffect(() => {
    fetchDailyStats();
  }, [fetchDailyStats]);

  const memoizedData = useMemo(() => data, [data]);

  if (error) {
    return <div>Error: {error}</div>;
  }

  if (loading) {
    return <div style={{ display: "flex", justifyContent: "center", paddingTop: "4rem" }}><Spin /></div>;
  }

  return (
    <div>
      <Suspense fallback={<div style={{ display: "flex", justifyContent: "center", paddingTop: "4rem" }}><Spin /></div>}>
        <Row gutter={[24, 0]}>
          <Col xs={24} md={12}>
            <br />
            <OverviewTable data={memoizedData} />
          </Col>
          <Col xs={24} md={12}>
            <Row gutter={[24, 0]}>
              <Col xs={24}>
                <NetPaL data={memoizedData} />
              </Col>
              <Col xs={24}>
                <NetPaLCumulative data={memoizedData} />
              </Col>
            </Row>
          </Col>
        </Row>
      </Suspense>
    </div>
  );
};

export default React.memo(TradingOverview);
