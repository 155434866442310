import React from "react";

const AssetProfitability = ({ overallStatsData }) => {
  const symbols = [
    {
      symbol: overallStatsData?.symbol1,
      percentage: Math.round(overallStatsData?.symbol1Percentage) || 0,
      color: overallStatsData?.symbol1Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
    {
      symbol: overallStatsData?.symbol2,
      percentage: Math.round(overallStatsData?.symbol2Percentage) || 0,
      color: overallStatsData?.symbol2Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
    {
      symbol: overallStatsData?.symbol3,
      percentage: Math.round(overallStatsData?.symbol3Percentage) || 0,
      color: overallStatsData?.symbol3Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
    {
      symbol: overallStatsData?.symbol4,
      percentage: Math.round(overallStatsData?.symbol4Percentage) || 0,
      color: overallStatsData?.symbol4Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
    {
      symbol: overallStatsData?.symbol5,
      percentage: Math.round(overallStatsData?.symbol5Percentage) || 0,
      color: overallStatsData?.symbol5Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
    {
      symbol: overallStatsData?.symbol6,
      percentage: Math.round(overallStatsData?.symbol6Percentage) || 0,
      color: overallStatsData?.symbol6Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
    {
      symbol: overallStatsData?.symbol7,
      percentage: Math.round(overallStatsData?.symbol7Percentage) || 0,
      color: overallStatsData?.symbol7Pal >= 0 ? "#00c49f" : "#ff5f5f",
    },
  ].sort((a, b) => b.percentage - a.percentage); // Sort by percentage (descending)

  return (
    <div className="percentage-bars">
      <h4>AssetProfitability</h4>
      {symbols.map((item, index) => (
        <div key={index} className="bar-container">
          <span className="symbol-label">{item.symbol}</span>
          <div className="bar">
            <div
              className="bar-fill"
              style={{
                width: `${
                  item.percentage < 80 && item.percentage > 0
                    ? item.percentage + 30
                    : item.percentage
                }%`,
                backgroundColor: item.color,
              }}
            ></div>
            <span className="percentage-label">{item.percentage}%</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default AssetProfitability;
