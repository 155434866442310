import { Table, Typography } from 'antd';
import React from 'react';

const DetailedStatistics = ({ overallStatsData }) => {
  const { Title } = Typography;
  // Extract symbols dynamically from the overallStatsData
  const symbolsData = Object.keys(overallStatsData)
    .filter((key) => key.startsWith('symbol') && key.match(/^symbol\d+$/)) // Only keys like 'symbol1', 'symbol2', etc.
    .map((symbolKey) => {
      const index = symbolKey.match(/\d+/)[0]; // Extract number from 'symbolX'
      return {
        symbol: overallStatsData[`symbol${index}`],
        percentage: overallStatsData[`symbol${index}Percentage`],
        pal: overallStatsData[`symbol${index}Pal`],
        gain: overallStatsData[`symbol${index}Gain`],
        trades: overallStatsData[`symbol${index}Trades`],
        won: overallStatsData[`symbol${index}Won`],
        loss: overallStatsData[`symbol${index}Loss`],
        pips: overallStatsData[`symbol${index}Pips`],
      };
    })
    .filter((symbolData) => symbolData.symbol); // Filter out empty or null symbols

  // Sort symbols by percentage in descending order
  const sortedData = symbolsData.sort((a, b) => b.percentage - a.percentage);

  // Columns for the table
  const columns = [
    { title: 'Asset Traded', dataIndex: 'symbol', key: 'symbol' },
    {
      title: 'Gain for Portfolio', dataIndex: 'pal', key: 'pal', render: (value) => (
        <span style={{ color: value >= 0 ? '#19C98D' : '#fda73c', background: value >= 0 ? "#e5faf3" : "#fef5ea", padding: "5px 8px", borderRadius: "6px" }}>{value?.toFixed(2) || "--"}</span>
      ),
    },
    {
      title: 'Total Pips', dataIndex: 'pips', key: 'pips', render: (value) => (
        <span style={{ color: value >= 0 ? '#19C98D' : '#fda73c', background: value >= 0 ? "#e5faf3" : "#fef5ea", padding: "5px 8px", borderRadius: "6px" }}>{value?.toFixed(2) || "--"}</span>
      ),
    },
    {
      title: 'Total Profit', dataIndex: 'gain', key: 'gain', render: (value) => (
        <span style={{ color: value >= 0 ? '#19C98D' : '#fda73c', background: value >= 0 ? "#e5faf3" : "#fef5ea", padding: "5px 8px", borderRadius: "6px" }}>{value?.toFixed(2) || "--"}</span>
      ),
    },
    { title: 'Trades', dataIndex: 'trades', key: 'trades' },
    {
      title: 'Won', dataIndex: 'won', key: 'won', render: (value) => (
        <span style={{ color: value >= 0 ? '#19C98D' : '#fda73c', background: value >= 0 ? "#e5faf3" : "#fef5ea", padding: "5px 8px", borderRadius: "6px" }}>{value || "--"}</span>
      ),
    },
    {
      title: 'Loss', dataIndex: 'loss', key: 'loss', render: (value) => (
        <span style={{ color: value >= 0 ? '#19C98D' : '#fda73c', background: value >= 0 ? "#e5faf3" : "#fef5ea", padding: "5px 8px", borderRadius: "6px" }}>{value || "--"}</span>
      ),
    },
  ];

  return (
    <div >
      <Title level={4} style={{ padding: "0 20px", paddingTop: "7px" }}>Detailed Statistics</Title>
      {/* <br /> */}
      <Table className='detailedStatics' scroll={{ x: "max-content" }} dataSource={sortedData} pagination={false} columns={columns} rowKey="symbol" />
    </div>
  );
};

export default DetailedStatistics;
