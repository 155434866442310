import React, { useEffect, useState } from "react";
import { Timeline, Typography } from "antd";
import Paragraph from "antd/lib/typography/Paragraph";

const RecentTradesCard = ({ data, useFor, monthlyGain }) => {
  const { Title, Text } = Typography;
  const [recentTrades, setRecentTrades] = useState([]);
  const [reverse, setReverse] = useState(false);

  useEffect(() => {
    if (data.length > 0) {
      setRecentTrades(data);
    }
  }, [data]);
  return (
    <div>
      {" "}
      <div
        bordered={false}
        className="criclebox h-full"
        style={{
          minWidth: useFor === "homePro" ? "260px" : "300px",
          width: useFor === "homePro" ? "270px" : "auto",
          padding: "4px",
          border: "1px solid #e7e7e7",
          borderRadius: "12px",
        }}
      >
        <div className="timeline-box">
          <Title level={5}>Recent Trades / Open Positions</Title>
          <Paragraph className="lastweek" style={{ marginBottom: 24 }}>
            this month{" "}
            <span
              className="bnb2"
              style={{ color: monthlyGain >= 0 ? "#00cd88" : "#f94b60" }}
            >
              {monthlyGain.toFixed(1)}%
            </span>
          </Paragraph>

          <div
            className="list"
            style={{ maxHeight: "594px", overflowY: "auto", padding: "6px" }}
          >
            <Timeline className="timelinelist" reverse={reverse}>
              {recentTrades.length > 0 &&
                recentTrades.map((t, index) => (
                  <Timeline.Item
                    color={t.color}
                    key={index}
                    style={{ paddingBottom: "0px" }}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Title level={5}>{t.title}</Title>
                      <Title
                        style={{ marginLeft: "30px", margin: "auto" }}
                        level={5}
                      >
                        {t.symbol}
                      </Title>
                      <Title
                        style={{
                          marginLeft: "30px",
                          color: t.price >= 0 ? "#00cd88" : "#f94b60",
                        }}
                        level={5}
                      >
                        ${t.price}
                      </Title>
                    </div>
                    <Text>{t.time}</Text>
                  </Timeline.Item>
                ))}
            </Timeline>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecentTradesCard;
