import {
  CaretDownOutlined,
  CaretUpOutlined,
  ToTopOutlined
} from "@ant-design/icons";
import {
  Button,
  Card,
  Col,
  Progress,
  Radio,
  Row,
  Tooltip,
  Typography,
  Upload,
  message
} from "antd";
import { useEffect, useRef, useState } from "react";
import Echart from "../components/chart/EChart";
import LineChart from "../components/chart/LineChart";

import { getDailyStatsData, getDepositWithdraw, getTradesHistory } from "../api/api";
import ava2 from "../assets/images/logo-atlassian.svg";
import ava6 from "../assets/images/logo-invision.svg";
import ava5 from "../assets/images/logo-jira.svg";
import ava1 from "../assets/images/logo-shopify.svg";
import ava3 from "../assets/images/logo-slack.svg";
import ava4 from "../assets/images/logo-spotify.svg";
import team1 from "../assets/images/team-1.jpg";
import team2 from "../assets/images/team-2.jpg";
import team3 from "../assets/images/team-3.jpg";
import team4 from "../assets/images/team-4.jpg";
import Breadcrumbs from "../components/Breadcrumb/breadcrumb";
import RecentTradesCard from "../components/RecentTradesCard/recentTrades";
import { calculateDrawdown, calMonthlyGain, getAccountPercentage, getDrawdownPercentage, getMonthlyEquityGrowth, getPaLPercentage } from "../utils/utils";
import Loading from "../components/Loading/loading";
const dollor = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M8.43338 7.41784C8.58818 7.31464 8.77939 7.2224 9 7.15101L9.00001 8.84899C8.77939 8.7776 8.58818 8.68536 8.43338 8.58216C8.06927 8.33942 8 8.1139 8 8C8 7.8861 8.06927 7.66058 8.43338 7.41784Z"
      fill="#fff"
    ></path>
    <path
      d="M11 12.849L11 11.151C11.2206 11.2224 11.4118 11.3146 11.5666 11.4178C11.9308 11.6606 12 11.8861 12 12C12 12.1139 11.9308 12.3394 11.5666 12.5822C11.4118 12.6854 11.2206 12.7776 11 12.849Z"
      fill="#fff"
    ></path>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 18C14.4183 18 18 14.4183 18 10C18 5.58172 14.4183 2 10 2C5.58172 2 2 5.58172 2 10C2 14.4183 5.58172 18 10 18ZM11 5C11 4.44772 10.5523 4 10 4C9.44772 4 9 4.44772 9 5V5.09199C8.3784 5.20873 7.80348 5.43407 7.32398 5.75374C6.6023 6.23485 6 7.00933 6 8C6 8.99067 6.6023 9.76515 7.32398 10.2463C7.80348 10.5659 8.37841 10.7913 9.00001 10.908L9.00002 12.8492C8.60902 12.7223 8.31917 12.5319 8.15667 12.3446C7.79471 11.9275 7.16313 11.8827 6.74599 12.2447C6.32885 12.6067 6.28411 13.2382 6.64607 13.6554C7.20855 14.3036 8.05956 14.7308 9 14.9076L9 15C8.99999 15.5523 9.44769 16 9.99998 16C10.5523 16 11 15.5523 11 15L11 14.908C11.6216 14.7913 12.1965 14.5659 12.676 14.2463C13.3977 13.7651 14 12.9907 14 12C14 11.0093 13.3977 10.2348 12.676 9.75373C12.1965 9.43407 11.6216 9.20873 11 9.09199L11 7.15075C11.391 7.27771 11.6808 7.4681 11.8434 7.65538C12.2053 8.07252 12.8369 8.11726 13.254 7.7553C13.6712 7.39335 13.7159 6.76176 13.354 6.34462C12.7915 5.69637 11.9405 5.26915 11 5.09236V5Z"
      fill="#fff"
    ></path>
  </svg>,
];
const profile = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      d="M9 6C9 7.65685 7.65685 9 6 9C4.34315 9 3 7.65685 3 6C3 4.34315 4.34315 3 6 3C7.65685 3 9 4.34315 9 6Z"
      fill="#fff"
    ></path>
    <path
      d="M17 6C17 7.65685 15.6569 9 14 9C12.3431 9 11 7.65685 11 6C11 4.34315 12.3431 3 14 3C15.6569 3 17 4.34315 17 6Z"
      fill="#fff"
    ></path>
    <path
      d="M12.9291 17C12.9758 16.6734 13 16.3395 13 16C13 14.3648 12.4393 12.8606 11.4998 11.6691C12.2352 11.2435 13.0892 11 14 11C16.7614 11 19 13.2386 19 16V17H12.9291Z"
      fill="#fff"
    ></path>
    <path
      d="M6 11C8.76142 11 11 13.2386 11 16V17H1V16C1 13.2386 3.23858 11 6 11Z"
      fill="#fff"
    ></path>
  </svg>,
];
const heart = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M3.17157 5.17157C4.73367 3.60948 7.26633 3.60948 8.82843 5.17157L10 6.34315L11.1716 5.17157C12.7337 3.60948 15.2663 3.60948 16.8284 5.17157C18.3905 6.73367 18.3905 9.26633 16.8284 10.8284L10 17.6569L3.17157 10.8284C1.60948 9.26633 1.60948 6.73367 3.17157 5.17157Z"
      fill="#fff"
    ></path>
  </svg>,
];
const cart = [
  <svg
    width="22"
    height="22"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    key={0}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10 2C7.79086 2 6 3.79086 6 6V7H5C4.49046 7 4.06239 7.38314 4.00612 7.88957L3.00612 16.8896C2.97471 17.1723 3.06518 17.455 3.25488 17.6669C3.44458 17.8789 3.71556 18 4 18H16C16.2844 18 16.5554 17.8789 16.7451 17.6669C16.9348 17.455 17.0253 17.1723 16.9939 16.8896L15.9939 7.88957C15.9376 7.38314 15.5096 7 15 7H14V6C14 3.79086 12.2091 2 10 2ZM12 7V6C12 4.89543 11.1046 4 10 4C8.89543 4 8 4.89543 8 6V7H12ZM6 10C6 9.44772 6.44772 9 7 9C7.55228 9 8 9.44772 8 10C8 10.5523 7.55228 11 7 11C6.44772 11 6 10.5523 6 10ZM13 9C12.4477 9 12 9.44772 12 10C12 10.5523 12.4477 11 13 11C13.5523 11 14 10.5523 14 10C14 9.44772 13.5523 9 13 9Z"
      fill="#fff"
    ></path>
  </svg>,
];

const toDate = new Date();
const comingDate = new Date(toDate);
comingDate.setDate(toDate.getDate() + 1);

// Format dates to YYYY-MM-DD using template literals for better readability
const formatDate = (date) =>
  `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, "0")}-${String(date.getDate()).padStart(2, "0")}`;

const currentYearStart = new Date(toDate.getFullYear(), 0, 1); // January 1st of the current year
const currentYearEnd = comingDate;

function Home() {
  const { Title } = Typography;
  const [data, setData] = useState([]);
  const [trades, setTradesData] = useState([]);
  const [profitLoss, setProfitLoss] = useState(0);
  const [accBalance, setAccBalance] = useState(0);
  const [selectedFilter, setSelectedFilter] = useState("yearly");
  const [drawdown, setDrawdown] = useState(0);
  const [monthlyGain, setMonthlyGain] = useState(0);
  const [statsData, setStatsData] = useState([]);
  const [equityChartData, setEquityChartData] = useState([]);
  const [loginId, setLoginId] = useState(localStorage.getItem('loginId'));
  const [selectedDate, setSelectedDate] = useState({
    fromDate: formatDate(currentYearStart),
    toDate: formatDate(currentYearEnd),
  });
  const selectedDateRef = useRef({
    fromDate: formatDate(currentYearStart),
    toDate: formatDate(currentYearEnd),
  });

  useEffect(() => {
    // Set up an interval to periodically check for changes in userType from localStorage
    let interval;
    if (!loginId) {
      interval = setInterval(() => {
        const storedUser = localStorage.getItem('loginId');
        console.log('storedUser', storedUser);

        setLoginId(storedUser)
      }, 1000); // Check every 800ms
      // Cleanup the interval on component unmount
      return () => clearInterval(interval);
    }
  }, []);
  console.log('loginId', loginId);

  const getAccountBalance = () => {
    const accountBalance = localStorage.getItem("accountBalance");
    return accountBalance && accountBalance !== 'null' && accountBalance !== "undefined" ? accountBalance : 0;
  };
  useEffect(() => {
    const fetchDataAndBalance = () => {
      fetchData();
      fetchTradeData();
      setAccBalance(getAccountBalance());
    };

    fetchDataAndBalance();
    const timeoutID = setTimeout(() => {
      fetchDataAndBalance();
    }, 4000);

    const intervalID = setInterval(fetchDataAndBalance, 6000);

    return () => {
      clearTimeout(timeoutID)
      clearInterval(intervalID);
    }
  }, [])

  useEffect(() => {
    fetchData();
    fetchTradeData();
  }, [selectedDate]);

  useEffect(() => {
    const initialDrawdown = calculateDrawdown(statsData, selectedFilter);
    setDrawdown(initialDrawdown);
  }, [statsData]);

  const fetchData = () => {
    const loginId = localStorage.getItem('loginId');
    if (!loginId) return;

    getDailyStatsData(loginId, selectedDateRef.current.fromDate, selectedDateRef.current.toDate)
      .then((res) => {
        const dailyStatsData = res.data;
        if (Array.isArray(dailyStatsData) && dailyStatsData.length) {
          setStatsData(dailyStatsData);
          const pal = dailyStatsData.filter((item) => {
            const itemDate = new Date(item.date);
            return itemDate.getDate() === new Date().getDate() && itemDate.getMonth() === new Date().getMonth();
          });

          setProfitLoss(pal?.[0]?.pal || 0);
        } else {
          setProfitLoss(0);
          setStatsData([]);
        }
      })
      .catch((err) => console.error(err));

    const currentYear = new Date().getFullYear();
    const startOfYear = `${currentYear - 1}-12-08`;
    const startOfYearForTransactions = `${currentYear}-01-01`;
    const endOfYear = `${currentYear}-12-31`;

    getDailyStatsData(loginId, startOfYear, endOfYear)
      .then((res) => {
        getDepositWithdraw(loginId, startOfYearForTransactions, endOfYear).then((res2) => {
          const dailyStatsData = res.data;
          const depositWithdraw = res2.data;
          const equityGrowthData = getMonthlyEquityGrowth(dailyStatsData, depositWithdraw).map((value) => value || 0);
          setEquityChartData(equityGrowthData);
        }).catch((err) => console.error(err));
      })
      .catch((err) => console.error(err));
  };

  const fetchTradeData = () => {
    const loginId = localStorage.getItem('loginId');
    console.log('loginId', loginId);

    if (!loginId) return;

    getTradesHistory(loginId, selectedDateRef.current.fromDate, selectedDateRef.current.toDate)
      .then((res) => {
        console.log('selectedFilter', selectedDateRef);

        const recentTrades = res?.data?.slice(-15)?.filter((item) => item.symbol)?.map((trade) => ({
          title: trade.type === "BUY" ? "Buy" : "Sell",
          time: new Date(trade.openTime).toLocaleString("en-GB", {
            day: "2-digit",
            month: "short",
            hour: "2-digit",
            minute: "2-digit",
            hour12: true,
          }),
          color: trade.type === "BUY" ? "#00cd88" : "#f94b60",
          symbol: trade.symbol,
          price: trade.pal.toFixed(2),
        }));
        setData(recentTrades || []);
        setTradesData(res?.data);
        console.log('data', res?.data);


        if (selectedFilter === 'yearly') {
          const gain = calMonthlyGain(res?.data)
          setMonthlyGain(gain || 0)
        }

      })
      .catch((err) => console.error(err));
  };
  const onChange = (e) => console.log(`radio checked:${e.target.value}`);
  const palPercentage = getPaLPercentage(statsData, +accBalance)
  const accountBalancePer = getAccountPercentage(statsData, +accBalance)
  const drawdownPer = getDrawdownPercentage(statsData, +accBalance)

  const handleDateFilterChange = (e) => {
    const filterValue = e.target.value;
    setSelectedFilter(filterValue);

    const toDate = new Date();
    let fromDate = new Date(toDate);

    switch (filterValue) {
      case "yearly":
        // Set fromDate to January 1st of the current year
        fromDate = new Date(toDate.getFullYear(), 0, 1);
        break;
      case "monthly":
        // Set fromDate to the first day of the current month
        fromDate = new Date(toDate.getFullYear(), toDate.getMonth(), 1);
        break;
      case "weekly":
        // Set fromDate to the most recent Monday
        const dayOfWeek = toDate.getDay(); // 0 (Sunday) - 6 (Saturday)
        const offset = (dayOfWeek === 0 ? 6 : dayOfWeek - 1); // Calculate offset for Monday
        fromDate.setDate(toDate.getDate() - offset);
        break;
      case "daily":
        // Set fromDate to the current day (same as toDate)
        fromDate = new Date(toDate);
        toDate.setDate(toDate.getDate() + 1);
        break;
      default:
        fromDate = new Date(toDate);
    }

    const formattedFromDate = formatDate(fromDate);
    const formattedToDate = formatDate(toDate);
    selectedDateRef.current = { fromDate: formattedFromDate, toDate: formattedToDate };
    setSelectedDate({ fromDate: formattedFromDate, toDate: formattedToDate });

    const updatedDrawdown = calculateDrawdown(statsData, filterValue);
    setDrawdown(updatedDrawdown);
  };



  const count = [
    {
      today: "Profit & Loss",
      title: `$ ${profitLoss}`,
      persent: palPercentage,
      icon: dollor,
      bnb: "bnb2",
    },
    {
      today: "Account Balance",
      title: `$${accBalance == null ? 0 : accBalance}`,
      persent: accountBalancePer,
      icon: profile,
      bnb: "bnb2",
    },
    {
      today: "Drawdown",
      title: drawdown || 0,
      persent: drawdownPer,
      icon: heart,
      bnb: "bnb2",
    },
    {
      today: "Average Salary",
      title: "$13,2000",
      persent: -10,
      icon: cart,
      bnb: "bnb2",
      action: 'comingSoon'
    },
  ];

  const list = [
    {
      img: ava1,
      Title: "Soft UI Shopify Version",
      bud: "$14,000",
      progress: <Progress percent={60} size="small" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Alexander Smith">
            <img className="tootip-img" src={team3} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Jessica Doe">
            <img className="tootip-img" src={team4} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava2,
      Title: "Progress Track",
      bud: "$3,000",
      progress: <Progress percent={10} size="small" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava3,
      Title: "Fix Platform Errors",
      bud: "Not Set",
      progress: <Progress percent={100} size="small" status="active" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Alexander Smith">
            <img className="tootip-img" src={team3} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava4,
      Title: "Launch new Mobile App",
      bud: "$20,600",
      progress: <Progress percent={100} size="small" status="active" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
        </div>
      ),
    },
    {
      img: ava5,
      Title: "Add the New Landing Page",
      bud: "$4,000",
      progress: <Progress percent={80} size="small" />,
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Alexander Smith">
            <img className="tootip-img" src={team3} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Jessica Doe">
            <img className="tootip-img" src={team4} alt="" />
          </Tooltip>
        </div>
      ),
    },

    {
      img: ava6,
      Title: "Redesign Online Store",
      bud: "$2,000",
      progress: (
        <Progress
          percent={100}
          size="small"
          status="exception"
          format={() => "Cancel"}
        />
      ),
      member: (
        <div className="avatar-group mt-2">
          <Tooltip placement="bottom" title="Ryan Tompson">
            <img className="tootip-img" src={team1} alt="" />
          </Tooltip>
          <Tooltip placement="bottom" title="Romina Hadid">
            <img className="tootip-img" src={team2} alt="" />
          </Tooltip>
        </div>
      ),
    },
  ];


  const uploadProps = {
    name: "file",
    action: "https://www.mocky.io/v2/5cc8019d300000980a055e76",
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (info.file.status === "done") {
        message.success(`${info.file.name} file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  return (loginId ?
    <>
      <div className="layout-content">
        <Row gutter={[24, 0]}>
          <Col span={24} md={12}>
            <Breadcrumbs />
          </Col>
          <Col span={24} md={12}>
            <div className="preview-date-filter" style={{ display: "flex", justifyContent: "end", height: "100%", alignItems: "center" }}>
              <Radio.Group
                value={selectedFilter}
                onChange={handleDateFilterChange}
                className="trade-history-radio-btn"
                style={{
                  marginRight: "20px",
                  background: "#f9f9f9",
                  padding: "2px",
                  borderRadius: "10px",
                  fontWeight: "600",
                  color: "#F9F9F9",
                  border: "1px solid #D3D3DB",
                }}
              >
                <Radio.Button value="yearly">
                  1 Year
                </Radio.Button>
                <Radio.Button value="monthly">
                  1 Month
                </Radio.Button>
                <Radio.Button value="weekly">
                  1 Week
                </Radio.Button>
                <Radio.Button value="daily">
                  1 Day
                </Radio.Button>
              </Radio.Group>
            </div>
          </Col>
        </Row>

        <Row className="rowgap-vbox" gutter={[24, 0]}>
          {count.map((c, index) => (
            <Col
              key={index}
              xs={24}
              sm={24}
              md={12}
              lg={12}
              xl={8}
              xxl={6}
              className="mb-24"
            >
              <div className="card-container">
                <Card bordered={false} className="criclebox card-content">
                  <div className="number">
                    <span>{c.today}</span>
                    <Row align="middle" gutter={[24, 0]} justify={"space-between"}>
                      <Col xs={24} style={{ display: "flex", alignItems: "center" }}>
                        <Title level={2} style={{ fontWeight: "bold", padding: "0px", fontSize: "32px", fontWeight: "600", marginTop: "4.1px", marginBottom: "4px" }}>
                          {c.title}
                        </Title>
                        <div className="preview-cards-percentage-box" style={{ width: "fitContent", background: c.persent >= 0 ? "#e6f4f0" : "#ffe8e8" }} ><small style={{ color: c.persent >= 0 ? "#069a6e" : "#ff4d4f" }} className={c.bnb}>{c.persent >= 0 && "+"}{c.persent}% {c.persent >= 0 ? <CaretUpOutlined CaretDownOutlined style={{ color: "#069a6e" }} /> : <CaretDownOutlined style={{ color: "#ff4d4f" }} />}</small></div>
                      </Col>
                    </Row>
                    <Row gutter={[24, 0]}>
                      {/* {(c.today === "Drawdown" || c.today === "Profit & Loss") && <Col xs={24}> */}
                      <Col xs={24}>
                        <div className="drawdown-bar">
                          {c.today === "Profit & Loss" ? <div>Daily Target</div> : c.today === "Drawdown" ? <div>Drawdown Limit</div> : c.today === "Account Balance" ? <div className="signs"><span>-</span><span>+</span></div> : <div className="signs"><span>$0</span><span> $2300</span></div>}

                          <div className="custom-progress">
                            <Progress
                              percent={40}
                              showInfo={false}
                              strokeColor={{
                                '0%': '#5351F1',   // Deep blue at the start
                                '100%': '#5351F1'  // Light blue at the end
                              }}
                              strokeWidth={11} // Increased thickness for a more bold look
                              trailColor="#f0f0f0" // Light gray for background bar
                            />
                          </div>
                        </div>
                      </Col>
                    </Row>
                  </div>
                </Card>

                {/* Blur Overlay */}
                {c?.action === "comingSoon" && (
                  <div className="blur-overlay">
                    <div className="blur-overlay-text">Coming Soon</div>
                  </div>
                )}
              </div>
            </Col >
          ))
          }
        </Row >

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <Echart trades={trades} selectedFilter={selectedFilter} />
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={12} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <LineChart equityChartData={equityChartData} loginId={loginId} />
            </Card>
          </Col>
        </Row>

        <Row gutter={[24, 0]}>
          <Col xs={24} sm={24} md={24} lg={24} xl={10} className="mb-24">
            <Card bordered={false} className="criclebox cardbody h-full">
              <div>
                <div className="project-ant">
                  <div>
                    <Title level={5}>Trading Vision Board</Title>
                    {/* <Paragraph className="lastweek">
                    done this month<span className="#5351F1">40%</span>
                  </Paragraph> */}
                  </div>
                  <div className="ant-filtertabs">
                    <div className="antd-pro-pages-dashboard-analysis-style-salesExtra">
                      <Radio.Group onChange={onChange} defaultValue="a">
                        <Radio.Button value="a">ALL</Radio.Button>
                        <Radio.Button value="b">ONLINE</Radio.Button>
                        <Radio.Button value="c">STORES</Radio.Button>
                      </Radio.Group>
                    </div>
                  </div>
                </div>
                <div className="ant-list-box table-responsive">
                  <table className="width-100">
                    <thead>
                      <tr>
                        <th>Goals</th>
                        <th>Deadline</th>
                        <th>Monetary Target</th>
                        <th>COMPLETION</th>
                      </tr>
                    </thead>
                    <tbody>
                      {list.map((d, index) => (
                        <tr key={index}>
                          <td>
                            <h6 style={{ margin: "20px 0" }}>
                              <img
                                src={d.img}
                                alt=""
                                className="avatar-sm mr-10"
                              />{" "}
                              {d.Title}
                            </h6>
                          </td>
                          <td>{d.member}</td>
                          <td>
                            <span className="text-xs font-weight-bold">
                              {d.bud}{" "}
                            </span>
                          </td>
                          <td>
                            <div className="percent-progress">{d.progress}</div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
                <div className="uploadfile shadow-none">
                  <Upload {...uploadProps}>
                    <Button
                      type="dashed"
                      className="ant-full-box"
                      icon={<ToTopOutlined />}
                    >
                      <span className="click">Click to Upload</span>
                    </Button>
                  </Upload>
                </div>
              </div>

              {/* Blur Overlay */}
              <div className="blur-overlay">
                <div className="blur-overlay-text">Coming Soon</div>
              </div>
            </Card>
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={8} className="mb-24">
            <RecentTradesCard data={data} useFor={"home"} monthlyGain={monthlyGain} />
          </Col>
          <Col xs={24} sm={24} md={12} lg={12} xl={6} className="mb-24">
            <Card bordered={false} className="criclebox h-full">
              <div className="linechart">
                <Title level={5} style={{ fontWeight: "bold" }}>To Do List</Title>

              </div>

              {/* Blur Overlay */}
              <div className="blur-overlay">
                <div className="blur-overlay-text">Coming Soon</div>
              </div>
            </Card>
          </Col>
        </Row>

      </div >
    </> : <Loading />
  );
}

export default Home;
