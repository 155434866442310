import React from "react";

const Orders = ({ overallStatsData }) => {
  const data = [
    {
      day: "M",
      percentage: Math.round(overallStatsData?.tradesMonday) || 0,
      color: overallStatsData?.palMonday >= 0 ? "#00cd88" : "#f94b60",
    },
    {
      day: "T",
      percentage: Math.round(overallStatsData?.tradesTuesday) || 0,
      color: overallStatsData?.palTuesday >= 0 ? "#00cd88" : "#f94b60",
    },
    {
      day: "W",
      percentage: Math.round(overallStatsData?.tradesWednesday) || 0,
      color: overallStatsData?.palWednesday >= 0 ? "#00cd88" : "#f94b60",
    },
    {
      day: "T",
      percentage: Math.round(overallStatsData?.tradesThursday) || 0,
      color: overallStatsData?.palThursday >= 0 ? "#00cd88" : "#f94b60",
    },
    {
      day: "F",
      percentage: Math.round(overallStatsData?.tradesFriday) || 0,
      color: overallStatsData?.palFriday >= 0 ? "#00cd88" : "#f94b60",
    },
    {
      day: "S",
      percentage: Math.round(overallStatsData?.tradesSaturday) || 0,
      color: overallStatsData?.palSaturday >= 0 ? "#00cd88" : "#f94b60",
    },
    {
      day: "S",
      percentage: Math.round(overallStatsData?.tradesSunday) || 0,
      color: overallStatsData?.palSunday >= 0 ? "#00cd88" : "#f94b60",
    },
  ];
  return (
    <div className="percentage-bars">
      <h4>Orders</h4>
      {data.map((item, index) => (
        <div key={index} className="bar-container">
          <span className="day-label">{item.day}</span>
          <div className="bar">
            <div
              className="bar-fill"
              style={{
                width: `${
                  item.percentage < 80 && item.percentage > 0
                    ? item.percentage + 30
                    : item.percentage
                }%`,
                backgroundColor: item.color,
              }}
            ></div>
            <span className="percentage-label">{item.percentage}%</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Orders;
