import dayjs from 'dayjs';

export function formatTime(isoTime) {
    if (isoTime && isoTime !== 'null' && isoTime !== 'undefined') {

        const date = new Date(isoTime); // Parse UTC time from the database
        const localDate = new Date(isoTime); // Adjust to local timezone
        // const localDate = new Date(date.getTime() - date.getTimezoneOffset() * 60000); // Adjust to local timezone
        const now = new Date();

        const timeDifference = now - localDate; // Difference in milliseconds
        const seconds = Math.floor(timeDifference / 1000);
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const days = Math.floor(hours / 24);

        const dayNames = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        if (seconds < 60) {
            return `${seconds} sec ago`;
        } else if (minutes < 60) {
            return `${minutes} min ago`;
        } else if (hours < 24) {
            return `${hours} hr ago`;
        } else if (days === 1) {
            return "Yesterday";
        } else if (days < 7) {
            // Display day name for notifications within the last week
            return dayNames[localDate.getDay()];
        } else {
            // Display full date in MM-DD-YY format for older notifications
            const month = (localDate.getMonth() + 1).toString().padStart(2, "0");
            const day = localDate.getDate().toString().padStart(2, "0");
            const year = localDate.getFullYear().toString().slice(-2);
            return `${month}-${day}-${year}`;
        }
    } else {
        return null;
    }
}

export function calculateCumulativePAL(data, fromDate, toDate) {
    // Convert fromDate and toDate to Date objects
    const from = new Date(fromDate);
    const to = new Date(toDate);

    // Filter data within the date range and sum up the 'pal' values
    const cumulativePAL = data
        .filter((entry) => {
            const entryDate = new Date(entry.date);
            return entryDate >= from && entryDate <= to;
        })
        .reduce((sum, entry) => sum + parseFloat(entry.pal), 0);

    return cumulativePAL.toFixed(2); // Return cumulative PAL as a string with 2 decimal places
}

export const calculateDrawdown = (statsData) => {
    if (statsData.length) {
        const maxDrawdown = Math.max(
            ...statsData.map(data => data.drawdown)
        );
        return maxDrawdown;

    } else return null
};

// Function to get the monthly equity growth in percentage
export const getMonthlyEquityGrowth = (stats, depositWithdraw) => {
    const monthlyEquity = {};
    const currentMonth = new Date().getMonth();
    const currentYear = new Date().getFullYear();

    // Adjust stats to remove the effect of deposits and withdrawals
    const adjustedStats = stats.map((entry) => {
        const date = new Date(entry.date);
        const relevantTransactions = depositWithdraw.filter((dw) => {
            const dwDate = new Date(dw.closeTime);
            return (
                dwDate.getFullYear() < date.getFullYear() ||
                (dwDate.getFullYear() === date.getFullYear() && dwDate.getMonth() < date.getMonth()) ||
                (dwDate.getFullYear() === date.getFullYear() && dwDate.getMonth() === date.getMonth() && dwDate.getDate() <= date.getDate())
            ); // Compare only up to the day level, ignoring hours
        });

        const totalAdjustment = relevantTransactions.reduce((sum, dw) => sum + dw.pal, 0);

        return {
            ...entry,
            adjustedDayEndEquity: parseFloat(entry.dayEndEquity) - totalAdjustment, // Adjust equity
        };
    });

    // Organize adjusted stats by the last day of each month
    adjustedStats.forEach((entry) => {
        const date = new Date(entry.date);
        const month = date.getMonth();
        const year = date.getFullYear();

        const key = `${year}-${month}`;

        if (!monthlyEquity[key] || date > new Date(monthlyEquity[key].date)) {
            monthlyEquity[key] = entry;
        }
    });

    // Extract adjusted `dayEndEquity` values in chronological order (Jan to Dec)
    const equityData = Array.from({ length: currentMonth + 1 }, (_, i) => {
        const monthKey = `${currentYear}-${i}`;
        return monthlyEquity[monthKey] ? parseFloat(monthlyEquity[monthKey].adjustedDayEndEquity) : null;
    });

    // Check for December of the previous year
    const previousDecemberKey = `${currentYear - 1}-11`;
    const previousDecemberEquity = monthlyEquity[previousDecemberKey]
        ? parseFloat(monthlyEquity[previousDecemberKey].adjustedDayEndEquity)
        : null;

    // Calculate percentage growth
    const equityGrowthPercentage = equityData.map((equity, index, arr) => {
        if (index === 0) {
            // For January, check if previous December's equity exists
            if (previousDecemberEquity !== null && equity !== null) {
                return ((equity - previousDecemberEquity) / previousDecemberEquity) * 100;
            }
            return null; // Return null if no previous December data
        }
        if (equity === null || arr[index - 1] === null) {
            return null; // Handle missing data
        }
        const previousEquity = arr[index - 1];
        console.log('equityData', equityData);

        return ((equity - previousEquity) / previousEquity) * 100; // Percentage growth formula
    });

    return equityGrowthPercentage; // Return the percentage growth data
};



export function calculateDailyTradingHours(trades) {
    // Helper function to calculate hours between two dates
    function calculateHours(start, end) {
        const diffMs = end - start;
        return diffMs / (1000 * 60 * 60); // Convert ms to hours
    }

    // Get unique dates from both openTime and closeTime
    const uniqueDates = new Set();
    trades.forEach(trade => {
        if (trade.openTime) {
            uniqueDates.add(new Date(trade.openTime).toISOString().split("T")[0]);
        }
        if (trade.closeTime) {
            uniqueDates.add(new Date(trade.closeTime).toISOString().split("T")[0]);
        }
    });

    const dailyHours = {};
    console.log('render.........');

    // Calculate trading hours for each unique date
    uniqueDates.forEach(date => {
        dailyHours[date] = 0;
        trades.forEach(trade => {
            const openTime = trade.openTime ? new Date(trade.openTime) : null;
            const closeTime = trade.closeTime ? new Date(trade.closeTime) : null;
            const tradeDateOpen = openTime ? openTime.toISOString().split("T")[0] : null;
            const tradeDateClose = closeTime ? closeTime.toISOString().split("T")[0] : null;


            if (tradeDateOpen === date && tradeDateClose === date && openTime && closeTime) {
                // Both openTime and closeTime on the same date
                dailyHours[date] += calculateHours(openTime, closeTime);
            } else if (tradeDateOpen === date && openTime && (!closeTime || tradeDateClose !== date)) {
                // Only openTime on this date or openTime on date, closeTime on another date
                const endOfDay = new Date(date);
                endOfDay.setHours(23, 59, 59, 999);
                dailyHours[date] += calculateHours(openTime, endOfDay);
            } else if (tradeDateClose === date && closeTime && (!openTime || tradeDateOpen !== date)) {
                // Only closeTime on this date or closeTime on date, openTime on another date
                const startOfDay = new Date(date);
                startOfDay.setHours(0, 0, 0, 0);
                dailyHours[date] += calculateHours(startOfDay, closeTime);
            }
        });
    });

    return dailyHours;
}
function getDecimalPlaces(num) {
    if (num) {
        const str = num.toString();
        return str.includes('.') ? str.split('.')[1].length : 0;
    } else return 0
}
export function calculatePips(trade) {
    const { openPrice, closePrice, type } = trade
    if (openPrice && closePrice) {

        const decimalPlaces = Math.max(getDecimalPlaces(openPrice), getDecimalPlaces(closePrice));
        const multiplier = Math.pow(10, decimalPlaces); // 100000 for 5 decimal places, 1000 for 3 decimal places

        let difference;

        // Calculate difference based on trade type
        if (type === 'SELL' || type === 'BUY_LIMIT' || type === 'BUY_STOP') {
            difference = openPrice - closePrice; // For a sell trade, closePrice - openPrice
        } else if (type === 'BUY' || type === 'BUY_LIMIT' || type === 'BUY_STOP') {
            difference = closePrice - openPrice; // For a buy trade, openPrice - closePrice
        } else {
            console.error('Invalid trade type. Use "buy" or "sell".');
        }
        // Calculate pips
        const pips = (difference * multiplier) / 10;

        return pips;
    }
}
export const filterTradesByTime = (trades, selectedFilter) => {
    const today = dayjs();

    return trades?.filter((trade) => {
        const closeDate = dayjs(trade.closeTime);

        if (selectedFilter === 'daily') {
            const currentDate = today.format('YYYY-MM-DD');
            return closeDate.isSame(today, 'day'); // Filter trades from today only

        } else if (selectedFilter === 'weekly') {
            return closeDate.isSame(today, 'week'); // Filter trades from this week

        } else if (selectedFilter === 'monthly') {
            return closeDate.isSame(today, 'month'); // Filter trades from this month

        } else if (selectedFilter === 'yearly') {
            return closeDate.isSame(today, 'year'); // Filter trades from this year

        } else {
            return true; // No filtering if no specific filter is applied
        }
    });
};
export const getPaLPercentage = (statsData, accBalance) => {
    // Find the matching record by comparing dates

    const totalPal = statsData.reduce((sum, stats) => sum + stats.pal, 0)

    // If a matching stat is found, calculate PaL percentage
    if (totalPal) {
        const palPercentage = (totalPal / (accBalance - totalPal)) * 100; // Calculate percentage
        return palPercentage >= 1 ? palPercentage.toFixed(2) : palPercentage <= -1 ? palPercentage.toFixed(2) : palPercentage.toFixed(1)
    }

    // Return null or an appropriate value if no matching stat is found
    return null;
};
export const getAccountPercentage = (statsData, accBalance,) => {
    // Find the matching record by comparing dates

    const totalPal = statsData.reduce((sum, stats) => sum + stats.pal, 0)

    // If a matching stat is found, calculate PaL percentage
    if (totalPal) {
        const palPercentage = ((accBalance / (accBalance - totalPal)) - 1) * 100; // Calculate percentage
        return palPercentage >= 1 ? palPercentage.toFixed(2) : palPercentage <= -1 ? palPercentage.toFixed(2) : palPercentage.toFixed(1)
    }

    // Return null or an appropriate value if no matching stat is found
    return null;
};
export const getDrawdownPercentage = (statsData, accBalance,) => {
    // Find the matching record by comparing dates

    // If a matching stat is found, calculate PaL percentage
    if (statsData.length) {
        const drawdownPercentage = (((Math.max(...statsData.map(item => item.drawdown))) / accBalance) * 100); // Calculate percentage
        return drawdownPercentage >= 1 ? drawdownPercentage.toFixed(2) : drawdownPercentage <= -1 ? drawdownPercentage.toFixed(2) : drawdownPercentage.toFixed(1); // Return as a string with 2 decimal places
    }

    return null;
};
export const formatDataByFilter = (trades, filter) => {
    const formattedData = {};
    let sortedKeys = [];

    trades.forEach((trade) => {
        if (trade.openTime && trade.closeTime) {
            const closeDate = dayjs(trade.closeTime); // closeDate in dayjs format
            const gain = trade.gainPercentage;

            if (filter === "daily") {
                const todayStart = dayjs().startOf("day"); // Start of today
                const tomorrowStart = dayjs().add(1, "day").startOf("day"); // Start of tomorrow
                if (closeDate.isBetween(todayStart, tomorrowStart, null, "[)")) {
                    const tradeKey = closeDate.format("HH:mm:ss"); // Time of the trade
                    if (!formattedData[tradeKey]) formattedData[tradeKey] = [];
                    formattedData[tradeKey].push(gain); // Store individual gains for each trade
                }
            } else if (filter === "weekly") {
                const startOfWeek = dayjs().startOf("week"); // Start from Sunday
                const endOfWeek = dayjs().endOf("week"); // End on Saturday
                if (closeDate.isBetween(startOfWeek, endOfWeek, "day", "[]")) {
                    const dayName = closeDate.format("ddd"); // e.g., "Sun", "Mon"
                    if (!formattedData[dayName]) formattedData[dayName] = 0;
                    formattedData[dayName] += gain;
                }
            } else if (filter === "monthly") {
                const key = closeDate.format("D"); // Day of the month
                if (!formattedData[key]) formattedData[key] = 0;
                formattedData[key] += gain;
            } else if (filter === "yearly") {
                const yearMonth = closeDate.format("MMM"); // Month name (e.g., "Jan")
                if (!formattedData[yearMonth]) formattedData[yearMonth] = 0;
                formattedData[yearMonth] += gain;
            }
        }
    });

    // Ensure data for all required days, months, or years are included, even if missing
    if (filter === "weekly") {
        const orderDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        orderDays.forEach(day => {
            if (!formattedData[day]) formattedData[day] = 0;
        });
        sortedKeys = orderDays;
    } else if (filter === "monthly") {
        const daysInMonth = dayjs().daysInMonth();
        for (let i = 1; i <= daysInMonth; i++) {
            const dayKey = String(i);
            if (!formattedData[dayKey]) formattedData[dayKey] = 0;
        }
        sortedKeys = Object.keys(formattedData);
    } else if (filter === "yearly") {
        const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        monthsOfYear.forEach(month => {
            if (!formattedData[month]) formattedData[month] = 0;
        });
        sortedKeys = monthsOfYear;
    }

    // Sort keys based on the selected filter
    if (filter === "daily") {
        sortedKeys = Object.keys(formattedData).sort((a, b) => {
            return dayjs(a, "HH:mm:ss").diff(dayjs(b, "HH:mm:ss"));
        });
    } else if (filter === "monthly") {
        sortedKeys = sortedKeys.sort((a, b) => dayjs(a, "D").diff(dayjs(b, "D")));
    } else if (filter === "yearly") {
        sortedKeys = sortedKeys.sort((a, b) => dayjs(a, "MMM").month() - dayjs(b, "MMM").month());
    }

    // Map the sorted keys to the data
    const data = sortedKeys.map(key => {
        if (filter === "daily") {
            return formattedData[key] ? formattedData[key].reduce((acc, gain) => acc + gain, 0) : 0; // Sum of all individual gains
        }
        return formattedData[key] || 0; // Return 0 for missing keys
    });

    return { sortedKeys, data };
};

export const dailyStatsDataFilter = (statsData, filter) => {
    const formattedData = {};
    let sortedKeys = [];

    statsData.forEach((stat) => {
        const closeDate = dayjs(stat.date); // Convert `date` to dayjs format
        const pal = stat.pal; // Extract `pal`

        if (filter === "daily") {
            // const todayStart = dayjs().startOf("day");
            // const tomorrowStart = dayjs().add(1, "day").startOf("day");
            // if (closeDate.isBetween(todayStart, tomorrowStart, null, "[)")) {
            //     const tradeKey = closeDate.format("HH:mm:ss"); // Time of the stat
            //     if (!formattedData[tradeKey]) formattedData[tradeKey] = 0;
            //     formattedData[tradeKey] += pal;
            // }
            const today = dayjs().format("YYYY-MM-DD"); // Current date in YYYY-MM-DD format
            const dayKey = closeDate.format("YYYY-MM-DD");

            if (dayKey === today) {
                if (!formattedData[today]) formattedData[today] = 0;
                formattedData[today] += pal; // Sum up `pal` for the current date
            }
        } else if (filter === "weekly") {
            const startOfWeek = dayjs().startOf("week");
            const endOfWeek = dayjs().endOf("week");
            if (closeDate.isBetween(startOfWeek, endOfWeek, "day", "[]")) {
                const dayName = closeDate.format("ddd"); // e.g., "Sun", "Mon"
                if (!formattedData[dayName]) formattedData[dayName] = 0;
                formattedData[dayName] += pal;
            }
        } else if (filter === "monthly") {
            const key = closeDate.format("D"); // Day of the month
            if (!formattedData[key]) formattedData[key] = 0;
            formattedData[key] += pal;
        } else if (filter === "yearly") {
            const yearMonth = closeDate.format("MMM"); // Month name (e.g., "Jan")
            if (!formattedData[yearMonth]) formattedData[yearMonth] = 0;
            formattedData[yearMonth] += pal;
        }
    });

    // Ensure all keys for weekly, monthly, and yearly filters are populated
    if (filter === "weekly") {
        const orderDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
        orderDays.forEach((day) => {
            if (!formattedData[day]) formattedData[day] = 0;
        });
        sortedKeys = orderDays;
    } else if (filter === "monthly") {
        const daysInMonth = dayjs().daysInMonth();
        for (let i = 1; i <= daysInMonth; i++) {
            const dayKey = String(i);
            if (!formattedData[dayKey]) formattedData[dayKey] = 0;
        }
        sortedKeys = Object.keys(formattedData);
    } else if (filter === "yearly") {
        const monthsOfYear = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];
        monthsOfYear.forEach((month) => {
            if (!formattedData[month]) formattedData[month] = 0;
        });
        sortedKeys = monthsOfYear;
    }

    // Sort keys for specific filters
    if (filter === "daily") {
        // sortedKeys = Object.keys(formattedData).sort((a, b) => {
        //     return dayjs(a, "HH:mm:ss").diff(dayjs(b, "HH:mm:ss"));
        // });
        const today = dayjs().format("YYYY-MM-DD");
        return {
            sortedKeys: [today],
            data: [formattedData[today] || 0],
        };

    } else if (filter === "monthly") {
        sortedKeys = sortedKeys.sort((a, b) => dayjs(a, "D").diff(dayjs(b, "D")));
    } else if (filter === "yearly") {
        sortedKeys = sortedKeys.sort((a, b) => dayjs(a, "MMM").month() - dayjs(b, "MMM").month());
    }

    // Map sorted keys to data
    const data = sortedKeys.map((key) => formattedData[key] || 0);

    return { sortedKeys, data };
};

export const calMonthlyGain = (trades) => {
    // Get the current date and extract year and month
    const now = new Date();
    const currentMonth = now.getMonth(); // 0-based index

    // Filter trades that belong to the current month and year
    const currentMonthTrades = trades.filter(trade => {
        const tradeDate = new Date(trade.closeTime);
        return (
            tradeDate.getMonth() === currentMonth
        );
    });

    // Calculate the net gain percentage
    const netGainPercentage = currentMonthTrades.reduce((total, trade) => {
        return total + (trade.gainPercentage || 0);
    }, 0);

    return netGainPercentage;

}

export const getCurrencySymbol = (currency) => {
    switch (currency) {
        case 'EURO':
            return '€';
        case 'USD':
            return '$';
        case 'GBP':
            return '£';
        default:
            return '';
    }
}