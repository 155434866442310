import { Col, Row, Typography } from "antd";
import dayjs from 'dayjs';
import isBetween from "dayjs/plugin/isBetween";
import ReactApexChart from "react-apexcharts";
import { useMemo, useCallback } from "react";
import { calculateDailyTradingHours, calculatePips, filterTradesByTime, formatDataByFilter } from "../../utils/utils";

dayjs.extend(isBetween);

function EChart({ trades, selectedFilter }) {
  const { Title } = Typography;

  // Memoized filtered trades based on the selected filter
  const filteredTrades = useMemo(() => filterTradesByTime(trades, selectedFilter), [trades, selectedFilter]);

  // Memoized calculations for gain percentage
  const totalGainPercentage = useMemo(
    () =>
      trades
        .filter(trade => trade.openTime && trade.closeTime && trade.gainPercentage)
        .reduce((sum, trade) => sum + trade.gainPercentage, 0),
    [trades]
  );

  // Memoized calculation for trades taken
  const tradesTaken = useMemo(
    () =>
      trades
        .filter(trade => trade.openTime)
        .reduce((count) => count + 1, 0),
    [trades]
  );

  // Memoized calculation for total pips
  const totalPips = useMemo(
    () =>
      trades
        .filter(trade => trade !== undefined && trade !== null)
        .map(trade => {
          const pips = calculatePips(trade);
          return typeof pips === 'number' && !isNaN(pips) ? pips : 0;
        })
        .reduce((sum, pips) => sum + pips, 0),
    [trades]
  );

  // Memoized daily trading hours calculation
  const totalTradingHourWithDate = useMemo(() => calculateDailyTradingHours(filteredTrades), [filteredTrades]);

  // Function to calculate trading hours based on filter
  const getTradingHoursByFilter = useCallback((totalTradingHourWithDate, selectedFilter) => {
    const today = dayjs();

    if (selectedFilter === 'daily') {
      const currentDate = today.format('YYYY-MM-DD');
      return totalTradingHourWithDate[currentDate] || 0;
    }

    let totalHours = 0;
    Object.keys(totalTradingHourWithDate).forEach(date => {
      const dateObj = dayjs(date);

      if (selectedFilter === 'weekly' && dateObj.isSame(today, 'week')) {
        totalHours += totalTradingHourWithDate[date];
      } else if (selectedFilter === 'monthly' && dateObj.isSame(today, 'month')) {
        totalHours += totalTradingHourWithDate[date];
      } else if (selectedFilter === 'yearly' && dateObj.isSame(today, 'year')) {
        totalHours += totalTradingHourWithDate[date];
      }
    });

    return totalHours;
  }, []);

  const totalHours = useMemo(
    () => getTradingHoursByFilter(totalTradingHourWithDate, selectedFilter),
    [totalTradingHourWithDate, selectedFilter, getTradingHoursByFilter]
  );

  // Memoized items data
  const items = useMemo(
    () => [
      { Title: totalGainPercentage === 0 ? 0 : totalGainPercentage.toFixed(3), user: "Gain %" },
      { Title: Math.round(totalPips), user: "Pips" },
      { Title: tradesTaken, user: "Trades Taken" },
      { Title: Math.round(totalHours), user: "Hours in Trades" },
    ],
    [totalGainPercentage, totalPips, tradesTaken, totalHours]
  );

  // Memoized chart data and categories
  const { sortedKeys: xAxisCategories, data: gainPercentages } = useMemo(
    () => formatDataByFilter(trades, selectedFilter),
    [trades, selectedFilter]
  );

  // Chart configuration
  const eChart = useMemo(() => {
    const maxValue = Math.max(...gainPercentages); // Maximum value in the dataset
    const minValue = Math.min(...gainPercentages); // Minimum value in the dataset
    let stepSize;
  
    if (maxValue <= 10) {
      stepSize = 1;
    } else if (maxValue <= 30) {
      stepSize = 5;
    } else if (maxValue <= 100) {
      stepSize = 10;
    } else if (maxValue <= 500) {
      stepSize = 50;
    } else if (maxValue <= 1000) {
      stepSize = 100;
    } else {
      stepSize = Math.ceil(maxValue / 10); // Dynamically adjust for larger values
    }
  
    return {
      series: [
        {
          name: "Gain Percentage",
          data: gainPercentages.map(item => item?.toFixed(3)),
          color: "#5351F1",
        },
      ],
      options: {
        chart: { type: "bar", toolbar: { show: false } },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: selectedFilter === "monthly" ? "10" : "20",
            borderRadius: 5,
          },
        },
        fill: { type: "solid", opacity: 1 },
        dataLabels: { enabled: false },
        stroke: { width: 1, colors: ["transparent"] },
        grid: { borderColor: "#ccc", strokeDashArray: 2 },
        xaxis: {
          categories: xAxisCategories,
          labels: { style: { colors: Array(xAxisCategories.length).fill("#667085") } },
        },
        yaxis: {
          labels: {
            style: { colors: Array(10).fill("#667085") },
            formatter: val => (Number.isInteger(val) ? `${val}%` : ''), // Show only whole numbers
          },
          tickAmount: Math.ceil((maxValue - minValue) / stepSize), // Adjust tick amount
          min: Math.floor(minValue / stepSize) * stepSize, // Ensure min is a multiple of stepSize
          max: Math.ceil(maxValue / stepSize) * stepSize, // Ensure max is a multiple of stepSize
        },
        tooltip: { y: { formatter: val => `${val}%` } },
      },
    };
  }, [gainPercentages, selectedFilter, xAxisCategories]);
  
  // Memoized display text
  const displayText = useMemo(() => {
    const currentDate = new Date();
    const day = String(currentDate.getDate()).padStart(2, '0');
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const year = String(currentDate.getFullYear()).slice(-2);

    switch (selectedFilter) {
      case "yearly": return `Yearly Data - ${currentDate.getFullYear()}`;
      case "monthly": return `Monthly Data - ${currentDate.toLocaleString("default", { month: "short" })}-${currentDate.getFullYear()}`;
      case "weekly": return `Weekly Data - ${month}-${year}`;
      case "daily": return `Day Data - ${day}-${month}-${year}`;
      default: return "No Data Selected";
    }
  }, [selectedFilter]);

  return (
    <>
      <div className="linechart">
        <div>
          <Title style={{ margin: "0" }} level={4}>Track Record</Title>
          <p className="lastweek">{displayText}</p>
        </div>
        <div className="sales chart-portfolio-btn">Portfolio</div>
      </div>
      <div id="chart">
        <ReactApexChart className="bar-chart" options={eChart.options} series={eChart.series} type="bar" height={220} />
      </div>
      <div className="chart-vistior">
        <Row gutter={[24, 0]}>
          {items.map((v, index) => (
            <Col xs={6} xl={6} sm={6} md={6} key={index}>
              <div className="chart-visitor-count">
                <span>{v.user}</span>
                <Title level={4}>{v.Title}</Title>
              </div>
            </Col>
          ))}
        </Row>
      </div>
    </>
  );
}

export default EChart;
