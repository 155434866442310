import { CheckOutlined, CloseOutlined, InfoCircleFilled, MessageFilled } from "@ant-design/icons";
import {
    Button,
    Card,
    Checkbox,
    Modal,
    Radio,
    Row,
    Table
} from "antd";
import React, { useEffect, useState } from "react";
import ResponsivePagination from "../../Responsive/responsivePagination";
import ResponsiveScroll from "../../Responsive/responsiveScroll";
import { columnsOption, customTitles, defaultColumns } from "./columns";
export const filter = [
    <svg
        id="Layer_2"
        data-name="Layer 2"
        xmlns="http://www.w3.org/2000/svg"
        width="12"
        height="12"
        viewBox="0 0 50 51.27"
    >
        <defs>
            <style>
                {`.cls-1 {
        fill: #5351F1;
        stroke-width: 0px;
      }`}
            </style>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <path
                class="cls-1"
                d="M49.41,3.69c-2.49,3.51-4.98,7.02-7.48,10.53-2.96,4.18-5.91,8.36-8.9,12.52-.79,1.11-1.16,2.29-1.16,3.64.02,4.92.01,9.84,0,14.77,0,1.43-.36,1.97-1.71,2.48-3.14,1.19-6.29,2.38-9.44,3.57-.03.01-.05.04-.08.06h-.9c-.37-.24-.79-.43-1.11-.73-.51-.48-.57-1.14-.57-1.81,0-6.09-.02-12.19.02-18.27,0-1.43-.39-2.66-1.22-3.82C11.45,19.01,6.03,11.36.6,3.71-.14,2.68-.19,1.74.42.89.81.34,1.37.08,2.03.01,2.2,0,2.37,0,2.53,0h44.96c1.33,0,2.13.54,2.43,1.64.21.78-.07,1.43-.51,2.05Z"
            />
        </g>
    </svg>,
];
const tag = [<svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="16" height="16.01" viewBox="0 0 16 16.01">
    <defs>
        <style>{`
            .cls-1 {
                fill: #5351fa;
        }

            .cls-1, .cls-2 {
                stroke - width: 0px;
        }

            .cls-2 {
                fill: #fff;
        }`}
        </style>
    </defs>
    <g id="Layer_1-2" data-name="Layer 1">
        <g>
            <path class="cls-1" d="M9.06,0L.04.04l-.04,9.03,6.42,6.42c.7.7,1.83.7,2.53,0l6.53-6.53c.7-.7.7-1.82.01-2.52C13.32,4.24,9.09-.03,9.06,0Z" />
            <circle class="cls-2" cx="4.37" cy="4.6" r="1.42" />
        </g>
    </g>
</svg>]
const profitChart = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="84.64" height="34.83" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 84.64 34.83">
        <defs>
            <style>{`
      .proChart-1 {
        stroke-width: 1.5px;
      }

      .proChart-1, .proChart-2 {
        fill: none;
        stroke: #5e5bfb;
        stroke-linecap: round;
        stroke-linejoin: round;
      }

      .proChart-3 {
        fill: url(#linear-gradient);
        opacity: .4;
        stroke-width: 0px;
      }
    `}</style>
            <linearGradient id="linear-gradient" x1="42.5" y1="34.73" x2="42.5" y2="2.82" gradientTransform="translate(84.91) rotate(-180) scale(1 -1)" gradientUnits="userSpaceOnUse">
                <stop offset=".07" stop-color="#fff" stop-opacity="0" />
                <stop offset=".1" stop-color="rgba(245, 245, 254, .03)" stop-opacity=".03" />
                <stop offset=".27" stop-color="rgba(191, 190, 253, .2)" stop-opacity=".2" />
                <stop offset=".44" stop-color="rgba(148, 146, 252, .33)" stop-opacity=".33" />
                <stop offset=".61" stop-color="rgba(118, 116, 251, .42)" stop-opacity=".42" />
                <stop offset=".76" stop-color="rgba(100, 97, 251, .48)" stop-opacity=".48" />
                <stop offset=".89" stop-color="#5e5bfb" stop-opacity=".5" />
            </linearGradient>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <g>
                <path class="proChart-3" d="M6.91,27.1s1.83-3.45,1.83-3.16c0,.27,3.13-4.78,3.67-5.66.03-.05.09-.04.12,0l4.89,4.78s.09.03.12,0c5.52-6.05,10.82-14.05,15.29-22.04.03-.06.12-.05.14.01,2.52,6.72,6.39,11.83,10.45,14,.02.01.05.01.07,0,3.62-1.56,7.64-3.51,11.04-5.8.04-.03.09-.01.11.02l5.16,8.59c.04.06.12.05.15-.01l4.65-11.84c.02-.06.09-.06.14-.02l8.89,10.05,10.43-9v27.83H.75v-16.68" />
                <path class="proChart-2" d="M.75,18.27s6.01,8.63,6.01,8.93c0,.28,5.02-7.91,5.59-8.84.03-.05.09-.05.13,0l4.64,4.79s.09.03.13,0c5.8-6.36,10.95-13.94,15.65-22.34.03-.06.13-.05.15.01,2.65,7.06,6.05,11.87,10.32,14.15.02.01.05.01.08,0,3.8-1.64,7.48-3.69,11.05-6.1.04-.03.09-.02.12.03l5.43,9.03c.04.06.13.05.15-.02,0,0,4.48-12.46,4.52-12.41l8.87,10.63,10.31-9.08" />
                <path class="proChart-1" d="M.75,18.27s6.01,8.63,6.01,8.93c0,.28,5.02-7.91,5.59-8.84.03-.05.09-.05.13,0l4.64,4.79s.09.03.13,0c5.8-6.36,10.95-13.94,15.65-22.34.03-.06.13-.05.15.01,2.65,7.06,6.05,11.87,10.32,14.15.02.01.05.01.08,0,3.8-1.64,7.48-3.69,11.05-6.1.04-.03.09-.02.12.03l5.43,9.03c.04.06.13.05.15-.02,0,0,4.48-12.46,4.52-12.41l8.87,10.63,10.31-9.08" />
            </g>
        </g>
    </svg>
]
const lossChart = [
    <svg id="Layer_2" data-name="Layer 2" xmlns="http://www.w3.org/2000/svg" width="84.87" height="39.74" xmlnsXlink="http://www.w3.org/1999/xlink" viewBox="0 0 84.87 39.74">
        <defs>
            <style>{`
            .lsChart-1 {
              fill: none;
              stroke: #5e5bfb;
              stroke-linecap: round;
              stroke-linejoin: round;
              stroke-width: 1.5px;
            }
                    
            .lsChart-2 {
              fill: url(#linear-gradient);
              opacity: .4;
              stroke-width: 0px;
            }`}
            </style>
            <linearGradient id="linear-gradient" x1="36.41" y1="39.62" x2="36.41" y2="6.63" gradientTransform="translate(6.03)" gradientUnits="userSpaceOnUse">
                <stop offset=".07" stop-color="#fff" stop-opacity="0" />
                <stop offset=".1" stop-color="rgba(245, 245, 254, .03)" stop-opacity=".03" />
                <stop offset=".27" stop-color="rgba(191, 190, 253, .2)" stop-opacity=".2" />
                <stop offset=".44" stop-color="rgba(148, 146, 252, .33)" stop-opacity=".33" />
                <stop offset=".61" stop-color="rgba(118, 116, 251, .42)" stop-opacity=".42" />
                <stop offset=".76" stop-color="rgba(100, 97, 251, .48)" stop-opacity=".48" />
                <stop offset=".89" stop-color="#5e5bfb" stop-opacity=".5" />
            </linearGradient>
        </defs>
        <g id="Layer_1-2" data-name="Layer 1">
            <g>
                <path class="lsChart-2" d="M78.49,28.46s-.96-1.21-.96-.92c0,.27-3.9-6.09-4.44-6.98-.03-.05-.09-.04-.12,0l-4.21,4.12s-.09.03-.12,0c-5.52-6.05-10.63-12.38-15.1-20.37-.03-.06-.12-.05-.14.01-2.52,6.72-5.59,10.52-9.64,12.69-.02.01-.05.01-.07,0-3.62-1.56-7.19-3.12-10.58-5.42-.04-.03-.09-.01-.11.02l-5.12,7.88c-.04.06-.12.05-.15-.01l-4.35-10.89c-.02-.06-.09-.06-.14-.02l-11.47,10.75L.75.78v38.97s83.38,0,83.38,0v-19.51" />
                <path class="lsChart-1" d="M84.12,20.16s-5.72,8.21-5.72,8.49c0,.27-4.77-7.53-5.32-8.41-.03-.05-.09-.04-.12,0l-4.42,4.55s-.09.03-.12,0c-5.52-6.05-10.42-13.26-14.89-21.25-.03-.06-.12-.05-.14.01-2.52,6.72-5.76,11.3-9.82,13.47-.02.01-.05.01-.07,0-3.62-1.56-7.12-3.51-10.52-5.8-.04-.03-.09-.01-.11.02l-5.16,8.59c-.04.06-.12.05-.15-.01l-4.16-11.79c-.02-.06-.09-.06-.14-.02l-11.57,11.35L.75.75" />
            </g>
        </g>
    </svg>
]
const TradingActivity = ({ tradesHistoryData }) => {
    const [data, setData] = useState([]);
    const [allColumns, setAllColumns] = useState([]); // Store all columns
    const [visibleColumns, setVisibleColumns] = useState([]); // Columns currently visible in the table
    const [selectedColumns, setSelectedColumns] = useState([]); // Columns selected via checkboxes (default columns initially)
    const [childData, setChildData] = useState([]);
    const [visible, setVisible] = useState(false);
    const [pageSize, setPageSize] = useState(10); // Default rows per page
    const [scroll, setScroll] = useState({ x: "max-content", y: 500 }); // Default scroll values
    const [filteredData, setFilteredData] = useState([]); // Data after filtering by Radio selection
    const [selectedFilter, setSelectedFilter] = useState("history"); // Default to 'History'
    const [isLoading, setIsLoading] = useState(false); // Default to 'History'
    const [tradesActData, setTradeActData] = useState(tradesHistoryData || [])
    const [tradeCounts, setTradeCounts] = useState({
        openTrades: 0,
        openOrders: 0,
        history: 0,
    });
    const storeColumns = [];
    useEffect(() => {
        setTradeActData(tradesHistoryData)
        console.log(tradesHistoryData);

    }, [tradesHistoryData])
    useEffect(() => {
        setIsLoading(true);
        // Load selected columns from localStorage if they exist
        const savedColumns = localStorage.getItem("selectedColForRecords");
        if (savedColumns) {
            const parseColumns = JSON.parse(savedColumns);
            setSelectedColumns(parseColumns);
        } else {
            setSelectedColumns(defaultColumns);
        }
    }, []);

    useEffect(() => {
        if (allColumns.length > 0) {
            setVisibleColumns(
                allColumns.filter((col) => selectedColumns.includes(col.key))
            );
        }
    }, [selectedColumns, allColumns]);


    useEffect(() => {
        applyFilter();
    }, [selectedFilter, data]);

    useEffect(() => {
        setIsLoading(false);
        if (tradesActData?.length > 0) {
            const tradesData = tradesActData.map((trade) => ({
                ...trade,
                openTime: trade.openTime
                    ? formatDateToLocal(trade.openTime)
                    : "-- -- --",
                closeTime: trade.closeTime
                    ? formatDateToLocal(trade.closeTime)
                    : "-- -- --",
                closePrice: trade.closePrice || "---",
                info: "info",
                chart: trade.pal,
                chat: "chat"
            }));

            if (storeColumns.length === 0) {
                const columnsData = Object.keys(tradesData[0])?.filter(
                    (key) =>
                        key !== "tradeId" &&
                        key !== "loginId" &&
                        key !== "parentTicket" &&
                        key !== "createdAt" &&
                        key !== "updatedAt"
                )?.map((key) => ({
                    title: customTitles[key] === "Info" ? (
                        <span>
                            <InfoCircleFilled
                                style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    marginLeft: "8px"
                                }}
                            />
                        </span>
                    ) : customTitles[key] === "Chat" ? (
                        <span>
                            <InfoCircleFilled
                                style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    marginLeft: "8px"
                                }}
                            />
                        </span>
                    ) : customTitles[key] === "Chart" ? (
                        <span>
                            <InfoCircleFilled
                                style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                    marginLeft: "8px"
                                }}
                            />
                        </span>
                    ) : (
                        customTitles[key]
                    ),
                    dataIndex: key,
                    key: key,
                    width: customTitles[key] !== "Info" ? 110 : 50,
                    render: (text) => {
                        if (key === "info") {
                            return <div className="trade-act-icon-rows">{tag}</div>
                        } else if (key === "chat") {
                            return (
                                <MessageFilled style={{ fontSize: "24px", color: "#667085" }} />
                            );
                        } else if (key === "chart") {
                            return (<span>
                                {text >= 0 ? profitChart : lossChart}
                            </span>
                            );
                        } else if (key === "type") {
                            return (
                                text && <div style={{ color: text === "BUY" ? "#00cc88" : "#fdae4d", width: "fit-content", padding: "0 10px", height: "26px", background: text === "BUY" ? "#e2fcf5" : "#fef5ea", display: "flex", justifyContent: "center", alignItems: 'center', borderRadius: "5px" }}>
                                    {text.toUpperCase()}
                                </div >
                            );
                        } else if (key === "pal") {
                            return (
                                <span
                                    style={{
                                        color: text >= 0 ? "#00cc88" : "#fdae4d",
                                        fontWeight: "500",
                                    }}
                                >
                                    {text > 0 && "+"}
                                    {text}
                                </span>
                            );
                        } else if (key === "pips") {
                            return (
                                <span
                                    style={{
                                        color: text >= 0 ? "#00cc88" : "#fdae4d",
                                        fontWeight: "500",
                                    }}
                                >
                                    {text || "--"}
                                </span>
                            );
                        } else if (key === "openTime") {
                            return (
                                <span
                                    style={{
                                        fontWeight: "500",
                                    }}
                                >
                                    {text}
                                </span>
                            );
                        } else if (key === "closeTime") {
                            return (
                                <span
                                    style={{
                                        fontWeight: "500",
                                    }}
                                >
                                    {text}
                                </span>
                            );
                        } else if (key === "symbol") {
                            return (
                                <span style={{ color: "#5351F1", fontWeight: "500" }}>
                                    {text}
                                </span>
                            );
                        } else if (key === "gainPercentage") {
                            return (
                                <span
                                    style={{
                                        color: +text >= 0 ? "#00cc88" : "#fdae4d",
                                        fontWeight: "500",
                                    }}
                                >
                                    {+text >= 0 ? "+" : +text ? "" : "--"}
                                    {text}
                                    {+text ? "%" : ""}
                                </span>
                            );
                        }
                        return <span style={{ fontWeight: "400" }}>{text}</span>;
                    },
                }));

                // Add "Outcome" column after "Symbol" column

                const reorderedColumns = [
                    ...columnsData.filter((col) => col.key === "info"), // "Open Date" column
                    ...columnsData.filter((col) => col.key === "closeTime"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "symbol"), // "Symbol" column
                    ...columnsData.filter((col) => col.key === "type"), // "Open Date" column
                    ...columnsData.filter((col) => col.key === "lots"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "stopLoss"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "takeProfit"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "openPrice"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "closePrice"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "pips"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "pal"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "gainPercentage"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "chart"), // "Outcome" column
                    ...columnsData.filter((col) => col.key === "chat"), // "Outcome" column
                    ...columnsData.filter(
                        (col) =>
                            col.key !== "info" &&
                            col.key !== "closeTime" &&
                            col.key !== "symbol" &&
                            col.key !== "type" &&
                            col.key !== "lots" &&
                            col.key !== "stopLoss" &&
                            col.key !== "takeProfit" &&
                            col.key !== "openPrice" &&
                            col.key !== "closePrice" &&
                            col.key !== "pips" &&
                            col.key !== "pal" &&
                            col.key !== "gainPercentage" &&
                            col.key !== "chart" &&
                            col.key !== "chat"
                    ), // Remaining columns
                ];

                setAllColumns(reorderedColumns);
                setVisibleColumns(
                    columnsData.filter((col) => selectedColumns.includes(col.key))
                );
                storeColumns.push(columnsData);
            }

            const parentData = tradesData.filter(
                (item) => item.parentTicket === 0
            );
            setData(parentData);
            calculateCounts(parentData);
            setChildData(tradesData.filter((item) => item.parentTicket !== 0));
        } else {
            setAllColumns(columnsOption);
            setData([]);
        }

    }, [tradesActData])

    const formatDateToLocal = (dateString) => {
        const date = new Date(dateString);
        return date
            .toLocaleString("en-US", {
                year: "2-digit",
                month: "2-digit",
                day: "2-digit",
                hour: "2-digit",
                minute: "2-digit",
                hour12: false, // This will format it to AM/PM
            })
            .replace(/\//g, ".")
            .replace(",", ""); // Remove the comma; // Replace '/' with '.';
    };

    const showModal = () => {
        setVisible(true);
    };

    const handleOk = () => {
        const newVisibleColumns = allColumns.filter((col) =>
            selectedColumns.includes(col.key)
        );
        setVisibleColumns(newVisibleColumns); // Only set the visible columns
        localStorage.setItem("selectedColForRecords", JSON.stringify(selectedColumns)); // Save selected columns to localStorage
        setVisible(false);
    };

    const handleCancel = () => {
        setVisible(false);
    };

    const onCheckboxChange = (checkedValues) => {
        setSelectedColumns(checkedValues); // Update selected columns
    };

    // Handle "All", "None", and "Default" selections
    const selectAll = () => {
        setSelectedColumns(allColumns.map((col) => col.key));
    };

    const selectNone = () => {
        setSelectedColumns([]);
    };

    const selectDefault = () => {
        setSelectedColumns(defaultColumns);
    };

    const isAllSelected = selectedColumns.length === allColumns.length;
    const isNoneSelected = selectedColumns.length === 0;
    const isDefaultSelected =
        selectedColumns.sort().toString() === defaultColumns.sort().toString();

    const applyFilter = () => {
        let newFilteredData = data;
        if (selectedFilter === "openTrades") {
            newFilteredData = data.filter((trade) => trade.closeTime === "-- -- --");
        } else if (selectedFilter === "openOrders") {
            newFilteredData = data.filter((trade) => trade.openTime === "-- -- --");
        }
        setFilteredData(newFilteredData);
    };

    const calculateCounts = (tradesData) => {
        const openTrades = tradesData.filter(
            (trade) => trade.closeTime === "-- -- --"
        ).length;
        const openOrders = tradesData.filter(
            (trade) => trade.openTime === "-- -- --"
        ).length;
        const history = tradesData.length;
        setTradeCounts({ openTrades, openOrders, history });
    };
    return (
        <div>
            <div className="tabled">
                <Row gutter={[24, 0]}>
                    {/* <Col> */}
                    <Card
                        style={{ width: "100%", marginLeft: "15px", boxShadow: "none" }}
                        bordered={false}
                        className="criclebox tablespace mb-24"
                        title={
                            <div style={{ display: "flex", justifyContent: "space-between" }}>
                                <span>Trades Activity</span>

                                <div style={{ display: "flex" }}>
                                    <div>
                                        <Radio.Group
                                            value={selectedFilter}
                                            onChange={(e) => setSelectedFilter(e.target.value)}
                                            className="trade-history-radio-btn"
                                            style={{
                                                marginRight: "20px",
                                                background: "#f9f9f9",
                                                padding: "2px",
                                                borderRadius: "10px",
                                                fontWeight: "600",
                                                color: "#F9F9F9",
                                                border: "1px solid #D3D3DB",
                                            }}
                                        >
                                            <Radio.Button value="openTrades">
                                                Open Trades ({tradeCounts.openTrades})
                                            </Radio.Button>
                                            <Radio.Button value="openOrders">
                                                Open Orders ({tradeCounts.openOrders})
                                            </Radio.Button>
                                            <Radio.Button value="history">
                                                History ({tradeCounts.history})
                                            </Radio.Button>
                                        </Radio.Group>
                                    </div>
                                    <Button
                                        type="link"
                                        onClick={showModal}
                                        style={{
                                            color: "#5351F1",
                                            fontSize: "15px",
                                            height: "40px",
                                            background: "#E6F0FF",
                                        }}
                                    >
                                        {filter}
                                        Filter
                                    </Button>
                                </div>
                            </div>
                        }
                    >
                        <div className="table-responsive">
                            <ResponsivePagination setPageSize={setPageSize} />
                            <ResponsiveScroll setScroll={setScroll} />
                            <Table
                                loading={isLoading}
                                className={`custom-ant-table ${data.length === 0 ? "layoutAuto" : "layoutFix"
                                    }`}
                                columns={visibleColumns
                                    .map((col) => ({
                                        ...col,
                                        width: col.width,
                                    }))
                                    .reduce((acc, col, index) => {
                                        if (col.title !== 'Info') {
                                            acc.push(col); // Add the original column
                                            acc.push({
                                                title: "", // Add spacer column
                                                key: `spacer_${index}`,
                                                width: 10,
                                            });
                                        }
                                        return acc;
                                    }, [])}
                                dataSource={filteredData.map((row) => ({
                                    ...row,
                                    spacer: "", // Add an empty value for the spacer column
                                }))}
                                rowKey="ticket"
                                // pagination={{ pageSize }} // Set dynamic pageSize
                                scroll={scroll} // Enable horizontal and vertical scroll
                                expandable={{
                                    expandedRowRender: (record) =>
                                        childData.some(
                                            (item) => record.ticket === item.parentTicket
                                        ) ? (
                                            <div className="nested-table">
                                                <Table
                                                    columns={visibleColumns}
                                                    dataSource={childData.filter(
                                                        (item) => record.ticket === item.parentTicket
                                                    )}
                                                    pagination={false}
                                                    rowKey="tradeId"
                                                    rowClassName={() => "nested-row"}
                                                />
                                            </div>
                                        ) : null,
                                    rowExpandable: (record) =>
                                        childData.some(
                                            (item) => record.ticket === item.parentTicket
                                        ),
                                }}
                            />
                        </div>
                    </Card>
                    {/* </Col> */}
                </Row>
            </div>
            <Modal
                title={
                    <div
                        style={{
                            fontSize: "22px",
                            fontWeight: "bold",
                            margin: "10px 0",
                            color: "#5351F1",
                        }}
                    >
                        Select Columns
                    </div>
                }
                visible={visible}
                onOk={handleOk}
                onCancel={handleCancel}
                closable={false}
                width={600}
                style={{ padding: "10px" }}
                bodyStyle={{ padding: "14px", backgroundColor: "#f7faff" }}
                okText="Select"
                okButtonProps={{
                    style: {
                        backgroundColor: "#5351F1",
                        color: "white",
                        fontWeight: "bold",
                    },
                }}
            >
                <div
                    style={{
                        marginBottom: "10px",
                        fontWeight: "Bold",
                        fontSize: "14px",
                        color: "#5351F1",
                    }}
                >
                    <Button
                        type="link"
                        onClick={selectAll}
                        style={{
                            fontWeight: isAllSelected ? "bold" : "normal",
                            color: "#5351F1",
                        }}
                    >
                        ALL
                    </Button>
                    <Button
                        type="link"
                        onClick={selectNone}
                        style={{
                            fontWeight: isNoneSelected ? "bold" : "normal",
                            color: "#5351F1",
                        }}
                    >
                        NONE
                    </Button>
                    <Button
                        type="link"
                        onClick={selectDefault}
                        style={{
                            fontWeight: isDefaultSelected ? "bold" : "normal",
                            color: "#5351F1",
                        }}
                    >
                        DEFAULT
                    </Button>
                </div>
                <p style={{ marginBottom: "27px", color: "gray", marginTop: "-5px" }}>
                    Select the columns you want to display in the table
                </p>
                <Checkbox.Group
                    options={columnsOption.map((col) => ({
                        label: col.title,
                        value: col.key,
                    }))}
                    value={selectedColumns} // Sync the checked state with the selected columns
                    onChange={onCheckboxChange}
                    style={{
                        display: "grid",
                        gridTemplateColumns: "repeat(2, 1fr)",
                        columnGap: "2px",
                        marginBottom: "30px",
                        rowGap: "5px",
                    }}
                />
            </Modal>
        </div>
    );
};

export default TradingActivity;
