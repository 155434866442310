import { Col, Row, Typography } from "antd";
import dayjs from 'dayjs';
import isBetween from "dayjs/plugin/isBetween";
import ReactApexChart from "react-apexcharts";
import { useEffect, useMemo, useState } from "react";
import { calculateDrawdown, dailyStatsDataFilter } from "../../utils/utils";
import { CheckOutlined, LoadingOutlined } from "@ant-design/icons";
import logo from "../../assets/images/logo-attachments/Capa_1Logo IB-1.png";


dayjs.extend(isBetween);

function TrackRecordChart({ trades, statsData, selectedFilter, refreshDataCallback }) {
    const { Title } = Typography;
    const [showOverlay, setShowOverlay] = useState(false);
    const [isLoadingOutline, setsLoadingOutline] = useState(false);
    const accountBalance = localStorage.getItem("accountBalance");
    const accountType = localStorage.getItem("accountType");
    const serverType = localStorage.getItem("serverType");
    const [forceRenderKey, setForceRenderKey] = useState(0); // Key to force re-render

    // Function to refresh data
    const refreshData = async () => {
        try {
            setsLoadingOutline(true);
            setShowOverlay(true);

            // Simulating data refresh
            await refreshDataCallback();
            setForceRenderKey((prevKey) => prevKey + 1);
            // Wait for a short delay before marking verification as complete
            setTimeout(() => {
                setsLoadingOutline(false);
            }, 1000);
        } catch (error) {
            console.error("Failed to refresh data:", error);
        }
    };

    // Memoized calculations and data
    const totalGainPercentage = useMemo(
        () =>
            trades
                .filter((trade) => trade.openTime && trade.closeTime && trade.gainPercentage)
                .reduce((sum, trade) => sum + trade.gainPercentage, 0),
        [trades, selectedFilter]
    );

    const deposit = useMemo(
        () =>
            trades
                .filter((trade) => trade.symbol === null && trade.pal > 0)
                .reduce((sum, trade) => sum + trade.pal, 0),
        [trades, selectedFilter, showOverlay]
    );

    const withdraw = useMemo(
        () =>
            trades
                .filter((trade) => trade.symbol === null && trade.pal < 0)
                .reduce((sum, trade) => {
                    return sum + trade.pal
                }, 0),
        [trades, selectedFilter]
    );
    console.log(withdraw);

    const maxDrawdown = useMemo(() => calculateDrawdown(statsData), [statsData]);

    const totalPaL = useMemo(
        () =>
            statsData
                .filter(Boolean)
                .reduce((sum, item) => sum + item.pal, 0),
        [statsData, selectedFilter]
    );

    const totalProfitFactor = useMemo(
        () =>
            statsData
                .filter(Boolean)
                .reduce((sum, item) => sum + item.profitFactor, 0),
        [statsData, selectedFilter]
    );

    const items = useMemo(
        () => [
            { Title: totalGainPercentage === 0 ? 0 : totalGainPercentage.toFixed(3), user: "Gain %" },
            { Title: Math.round(totalPaL), user: "Total Profit" },
            { Title: Math.abs(maxDrawdown), user: "Max Drawdown" },
            { Title: Math.round(totalProfitFactor), user: "Profit Factor" },
            { Title: Math.round(+accountBalance || 0), user: "Balance" },
            { Title: "--", user: "Avg Risk to Reward" },
            { Title: deposit, user: "Deposit" },
            { Title: withdraw <= 0 ? Math.round(Math.abs(withdraw)) : '--', user: "Withdraw" },
        ],
        [totalGainPercentage, maxDrawdown, totalPaL, deposit]
    );

    const { sortedKeys: xAxisCategories, data: palValues } = useMemo(
        () => dailyStatsDataFilter(statsData, selectedFilter),
        [statsData, selectedFilter]
    );

    const eChart = useMemo(() => {
        const maxValue = Math.max(...palValues);
        const minValue = Math.min(...palValues); // Find the minimum value
        let stepSize;
    
        if (maxValue <= 10 && minValue >= -10) {
            stepSize = 1;
        } else if (maxValue <= 30 && minValue >= -30) {
            stepSize = 5;
        } else if (maxValue <= 100 && minValue >= -100) {
            stepSize = 10;
        } else if (maxValue <= 500 && minValue >= -500) {
            stepSize = 50;
        } else if (maxValue <= 1000 && minValue >= -1000) {
            stepSize = 100;
        } else {
            stepSize = Math.ceil(Math.max(maxValue, Math.abs(minValue)) / 10); // Adjust for larger values
        }
    
        return {
            series: [
                {
                    name: "PAL",
                    data: palValues.map((val) => parseFloat(val.toFixed(3))),
                    color: "#5351F1",
                },
            ],
            options: {
                chart: { type: "bar", toolbar: { show: false } },
                plotOptions: {
                    bar: {
                        horizontal: false,
                        columnWidth: selectedFilter === "monthly" ? "10" : "20",
                        borderRadius: 5,
                    },
                },
                fill: { type: "solid", opacity: 1 },
                dataLabels: { enabled: false },
                stroke: { width: 1, colors: ["transparent"] },
                grid: { borderColor: "#ccc", strokeDashArray: 2 },
                xaxis: {
                    categories: xAxisCategories,
                    labels: {
                        style: {
                            colors: Array(xAxisCategories.length).fill("#667085"),
                        },
                    },
                },
                yaxis: {
                    labels: {
                        style: { colors: Array(10).fill("#667085") },
                        formatter: (val) => `${Math.round(val)}%`,
                    },
                    tickAmount: Math.ceil((maxValue - minValue) / stepSize), // Adjust ticks for the full range
                    min: Math.floor(minValue / stepSize) * stepSize, // Extend Y-axis to include the minimum value
                    max: Math.ceil(maxValue / stepSize) * stepSize, // Extend Y-axis to include the maximum value
                },
                tooltip: {
                    y: {
                        formatter: (val) => `${val}%`,
                    },
                },
            },
        };
    }, [palValues, selectedFilter, xAxisCategories]);
    
    const displayText = useMemo(() => {
        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, "0");
        const month = String(currentDate.getMonth() + 1).padStart(2, "0");
        const year = String(currentDate.getFullYear()).slice(-2);

        switch (selectedFilter) {
            case "yearly":
                return `Yearly Data - ${currentDate.getFullYear()}`;
            case "monthly":
                return `Monthly Data - ${currentDate.toLocaleString("default", { month: "short" })}-${currentDate.getFullYear()}`;
            case "weekly":
                return `Weekly Data - ${month}-${year}`;
            case "daily":
                return `Day Data - ${day}-${month}-${year}`;
            default:
                return "No Data Selected";
        }
    }, [selectedFilter]);
    const numberOfLogos = 1; // Number of logos to display
    return (
        <div key={forceRenderKey}>
            <div className="linechart">
                <div>
                    <Title style={{ margin: "0" }} level={4}>Track Record</Title>
                    <p className="lastweek">{displayText}</p>
                </div>
                <div className="sales chart-verify-btn" onClick={() => refreshData()}>
                    Click to verify data
                    <span style={{ marginLeft: "8px" }}>
                        {showOverlay && isLoadingOutline ? (
                            <LoadingOutlined spin />
                        ) : showOverlay && !isLoadingOutline ? (
                            <CheckOutlined style={{ fontWeight: "bolder", fontSize: "16px", color:"#08ce8c" }} />
                        ) : ''}
                    </span>
                </div>
            </div>
            {/* Add animated logos floating like balloons */}
            {showOverlay && (
                <div className="floating-logos-container">
                    {[...Array(numberOfLogos)].map((_, index) => (
                        <FloatingLogo key={index} index={index} totalLogos={numberOfLogos} />
                    ))}
                </div>
            )}

            <div className="account-detail-container">
                <div className="account-detail-box">
                    <div>
                        <div className="acc-type-head">Type</div>
                        <div className="acc-type-text">{serverType}</div>
                    </div>
                    <div className="side-line"></div>
                </div>

                <div className="account-detail-box m-left">
                    <div>
                        <div className="acc-type-head">Broker</div>
                        <div className="acc-type-text">IC Markets</div>
                    </div>
                    <div className="side-line"></div>
                </div>

                <div className="account-detail-box m-left">
                    <div>
                        <div className="acc-type-head">Trading</div>
                        <div className="acc-type-text">Manual</div>
                    </div>
                    <div className="side-line"></div>
                </div>

                <div className="account-detail-box m-left">
                    <div>
                        <div className="acc-type-head">Platform</div>
                        <div className="acc-type-text">{accountType === "mt5" ? "Meta Trader 5" : accountType === "mt4" ? "Meta Trader 4" : "--"}</div>
                    </div>
                    <div className="side-line"></div>
                </div>
            </div>
            <div id="chart">
                <ReactApexChart className="bar-chart" options={eChart.options} series={eChart.series} type="bar" height={300} />
            </div>
            <div className="chart-vistior">
                <Row gutter={[24, 10]} ga>
                    {items.map((v, index) => (
                        <Col xs={24} xl={6} sm={24} md={12} key={index}>
                            <div className="chart-visitor-count">
                                <span>{v.user}</span>
                                <Title level={4}>{v.Title}</Title>
                            </div>
                        </Col>
                    ))}
                </Row>
            </div>
            {showOverlay && <div className="track-record-overlay" onClick={() => {
                setShowOverlay(false);
                setsLoadingOutline(false);
            }}></div>}
        </div>
    );
}

const FloatingLogo = () => {
    const [positions, setPositions] = useState([]);
    const logoWidth = 130; // Width of each logo
    const logoHeight = 130; // Height of each logo
    const logoMargin = 80; // Margin between logos
    const speed = 1; // Speed of diagonal movement

    useEffect(() => {
        const logosInRow = Math.ceil(window.innerWidth / (logoWidth + logoMargin)); // Extra for wrapping
        const logosInCol = Math.ceil(window.innerHeight / (logoHeight + logoMargin)); // Extra for wrapping
        const totalLogos = logosInRow * logosInCol; // Total number of logos to fill the screen

        // Generate initial positions with alternating even/odd logic
        const filteredPositions = Array.from({ length: totalLogos }, (_, index) => {
            const row = Math.floor(index / logosInRow); // Row index
            const col = index % logosInRow; // Column index

            // Apply even first, then odd sequence based on the row and column parity
            const isEvenRow = row % 2 === 0;
            const isEvenCol = col % 2 === 0;
            const isOddRow = !isEvenRow;
            const isOddCol = !isEvenCol;

            // Only show logos on even and odd positions in sequence
            if ((isEvenRow && isEvenCol) || (isOddRow && isOddCol)) {
                const x = col * (logoWidth + logoMargin);
                const y = row * (logoHeight + logoMargin);
                return { x, y };
            }
            return null; // Skip this position if it doesn't follow the sequence
        }).filter(Boolean); // Remove null positions

        setPositions(filteredPositions);

        // Continuous animation for diagonal movement
        const animate = () => {
            setPositions((prevPositions) =>
                prevPositions.map((pos) => {
                    let newX = pos.x + speed; // Move right
                    let newY = pos.y + speed; // Move down

                    // Wrap horizontally and vertically
                    if (newX > window.innerWidth) newX -= window.innerWidth + logoWidth + logoMargin;
                    if (newY > window.innerHeight) newY -= window.innerHeight + logoHeight + logoMargin;

                    return { x: newX, y: newY };
                })
            );
        };

        const interval = setInterval(animate, 30); // Adjust interval for smoother or slower movement
        return () => clearInterval(interval); // Cleanup
    }, []);

    return (
        <>
            {positions.map((pos, index) => (
                <img
                    key={index}
                    src={logo}
                    alt="Floating Logo"
                    width={"150px"}
                    style={{
                        position: "absolute",
                        left: pos.x,
                        top: pos.y,
                    }}
                />
            ))}
        </>
    );
};


export default TrackRecordChart;
