import { useEffect, useState } from 'react';

const ResponsiveScroll = ({ setScroll }) => {
  // Function to handle scroll size adjustment based on screen size
  const handleResize = () => {
    const screenWidth = window.innerWidth;
    const screenHeight = window.innerHeight;

    const xScroll = 'max-content'; // Set horizontal scroll
    let yScroll;// Set vertical scroll

    if (screenWidth <= 768) {
      yScroll = 300;
    } else if (screenWidth > 768 && screenWidth < 1500) {
      yScroll = 350
    }else if (screenWidth > 1500 && screenWidth < 1800) {
      yScroll = 600
    } else {
      yScroll = 800
    }

    setScroll({ x: xScroll, y: window.innerHeight - 430 });
  };

  // Listen for resize event to dynamically adjust scroll
  useEffect(() => {
    handleResize(); // Set initial scroll on component mount
    window.addEventListener('resize', handleResize); // Adjust scroll on resize
    return () => window.removeEventListener('resize', handleResize); // Cleanup listener
  }, []);

  return null; // This component doesn't render anything visible
};

export default ResponsiveScroll;