import { Col, Row } from "antd";
import React from "react";

const TradeMetricsCards = ({ overallStatsData }) => {
  const count = [
    {
      title: "Days Traded",
      percent: overallStatsData?.daysTraded || "--",
    },
    {
      title: "Max Daily Loss",
      percent: overallStatsData?.maxDailyLoss || "--",
    },
    {
      title: "Profit Target",
      percent: "--",
    },
    {
      title: "Pip Target",
      percent: "--",
    },
    {
      title: "Profit Factor",
      percent: "--",
    },
    {
      title: "Average Return Per Trade",
      percent: overallStatsData?.avgReturnPerTrade || "--",
    },
    {
      title: "Average Win to Average Loss",
      percent:
        `${overallStatsData?.avgWin || ""} - ${
          overallStatsData && Math.abs(overallStatsData?.avgLoss || "")
        }` || "--",
    },
    {
      title: "Trade Wins",
      percent: overallStatsData?.tradeWins || "--",
    },
  ];

  return (
    <div>
      {" "}
      <Row className="rowgap-vbox" gutter={[24, 0]}>
        {count.map((c, index) => (
          <Col
            key={index}
            xs={24}
            sm={24}
            md={12}
            lg={8}
            xl={6}
            xxl={6}
            className="mb-24"
          >
            <div className="tradeMetricsCards" style={containerstyles}>
              <div style={titleStyle}>{c.title}</div>

              <p style={valuesStyle}>{c.percent}</p>
            </div>
          </Col>
        ))}
      </Row>
    </div>
  );
};

export default TradeMetricsCards;
const containerstyles = {
  border: "1px solid rgb(187 185 253)",
  background: "rgb(250 249 255)",
  height: "76px",
  borderRadius: "11px",
};
const titleStyle = {
  margin: "0 15px",
  marginTop: "14px",
  fontSize: "14px",
  fontWeight: "500",
};
const valuesStyle = {
  padding: "0 15px",
  fontSize: "18px",
  fontWeight: "700",
  color: "#5351fa",
  margin: 0,
};
