import { Col, Row } from "antd";
import React from "react";

const TradeIntelligence = () => {
  return (
    <div>
      <Row gutter={[24, 0]}>
        <Col></Col>
        <Col></Col>
      </Row>
    </div>
  );
};

export default TradeIntelligence;
