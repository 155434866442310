export const customTitles = {
    info: "Info",
    closePrice: "Close Price",
    openTime: "Open Date",
    symbol: "Symbol",
    ticket: "Ticket",
    type: "Action",
    closeTime: "Close Date",
    openPrice: "Open Price",
    gainPercentage: "Gain",
    pips: "Pips",
    lots: "Lots",
    stopLoss: "SL (Price)",
    takeProfit: "TP (Price)",
    pal: "Net Profit",
    swap: "Swap",
    commission: "Commission",
    magic: "Magic",
    drawdown: "Drawdown",
    comments: "Comments",
    chart: "Chart",
    chat: "Chat"
};

export const columnsOption = [
    { title: "Info", key: "info" },
    { title: "Close Date", key: "closeTime" },
    { title: "Close Date", key: "closeTime" },
    { title: "Comments", key: "comments" },
    { title: "Open Date", key: "openTime" },
    { title: "Commission", key: "commission" },
    { title: "Net Profit", key: "pal" },
    { title: "Open Price", key: "openPrice" },
    { title: "SL (Price)", key: "stopLoss" },
    { title: "Close Price", key: "closePrice" },
    { title: "Swap", key: "swap" },
    { title: "Gain", key: "gainPercentage" },
    { title: "Pips", key: "pips" },
    { title: "Symbol", key: "symbol" },
    { title: "Lots", key: "lots" },
    { title: "TP (Price)", key: "takeProfit" },
    { title: "Magic", key: "magic" },
    { title: "Ticket", key: "ticket" },
    { title: "Action", key: "type" },
    { title: "Chart", key: "chart" },
    { title: "Chat", key: "chat" }
];

// Default column keys for the "Default" selection
export const defaultColumns = [
    "info",
    "closeTime",
    "symbol",
    "type",
    "openPrice",
    "closePrice",
    "gainPercentage",
    "pips",
    "lots",
    "chart",
    "chat",
];

