import React from "react";

const TradesPlus = ({ tradesData }) => {
  const sortedTrades = tradesData?.sort(
    (a, b) => new Date(b.openTime) - new Date(a.openTime)
  );

  // Get the latest 30 trades
  let latest30Trades = sortedTrades
    ?.filter((trade) => trade?.symbol)
    ?.slice(0, 30);

  // Add empty trades to fill the array to 30 if needed
  if (latest30Trades?.length < 30) {
    const emptyPulses = Array(30 - latest30Trades.length).fill({ pal: null });
    latest30Trades = [...emptyPulses, ...latest30Trades];
  }

  // Calculate streaks
  const calculateStreaks = (trades) => {
    let bestWinningStreak = 0;
    let worstLosingStreak = 0;

    let currentWinningStreak = 0;
    let currentLosingStreak = 0;

    trades?.forEach((trade) => {
      if (trade.pal > 0) {
        // Increment winning streak and reset losing streak
        currentWinningStreak++;
        currentLosingStreak = 0;

        // Update best winning streak
        bestWinningStreak = Math.max(bestWinningStreak, currentWinningStreak);
      } else if (trade.pal < 0) {
        // Increment losing streak and reset winning streak
        currentLosingStreak++;
        currentWinningStreak = 0;

        // Update worst losing streak
        worstLosingStreak = Math.max(worstLosingStreak, currentLosingStreak);
      } else {
        // Reset both streaks on neutral `pal`
        currentWinningStreak = 0;
        currentLosingStreak = 0;
      }
    });

    return { bestWinningStreak, worstLosingStreak };
  };

  const { bestWinningStreak, worstLosingStreak } =
    calculateStreaks(latest30Trades);

  return (
    <div>
      <h3 style={{ margin: "0 auto" }}>TradesPlus</h3>
      <p style={{ fontSize: "12px", margin: "0", marginBottom: "10px" }}>
        Your trading pulse based on your last 30 trades.
      </p>
      <div className="trade-puls-container">
        {latest30Trades?.map((item, index) => (
          <div
            className="pulse"
            key={index}
            style={{
              background:
                item.pal === null
                  ? "gray"
                  : item.pal >= 0
                  ? "#00cd88"
                  : "#f94b60",
            }}
          ></div>
        ))}
      </div>
      <div className="trade-puls-count-container">
        <div>
          <div className="trade-puls-count">{bestWinningStreak}</div>
          <div className="trade-puls-text">Best Winning Streak</div>
        </div>
        <div>
          <div className="trade-puls-count">{worstLosingStreak}</div>
          <div className="trade-puls-text">Worst Losing Streak</div>
        </div>
        <div>
          <div className="trade-puls-count">1:4</div>
          <div className="trade-puls-text">Optimal Risk to Reward</div>
        </div>
      </div>
    </div>
  );
};

export default TradesPlus;
