import { CaretRightOutlined } from "@ant-design/icons";
import { Breadcrumb, Typography } from "antd";
import React from "react";
import { NavLink, useLocation } from "react-router-dom";

const Breadcrumbs = () => {
  const { Title, Text } = Typography;
  let { pathname } = useLocation();
  pathname = pathname.replace("/", "");
  return (
    <div>
      <Breadcrumb
        separator={<CaretRightOutlined style={{ color: "#d1d0d4" }} />}
      >
        <Breadcrumb.Item>
          <NavLink to="/" style={{ color: "#5351F1" }}>
            Dashboard
          </NavLink>
        </Breadcrumb.Item>
        <Breadcrumb.Item style={{ textTransform: "capitalize" }}>
          <span style={{ textTransform: "capitalize", color: "#858D9D" }}>
            {pathname.split("-").join(" ")}
          </span>
        </Breadcrumb.Item>
      </Breadcrumb>
      <div className="ant-page-header-heading">
        <span
          className="ant-page-header-heading-title"
          style={{ textTransform: "capitalize" }}
        >
          <Title
            style={{
              fontSize: "28px",
              color: "#1A1C21",
            }}
            level={2}
          >
            {pathname.replace("/", "").split("-").join(" ")}
          </Title>
        </span>
      </div>
    </div>
  );
};

export default Breadcrumbs;
