import { Typography } from "antd";
import ReactApexChart from "react-apexcharts";

function PortfolioAccountChart({ equityChartData, comparisonData }) {
  const { Title } = Typography;

  const allMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

  // Align equityChartData with allMonths
  const equityChartDataAligned = allMonths.map((month, index) => {
    return equityChartData[index] || 0; // Use 0 for months without data
  });

  const maxValue = Math.max(...equityChartDataAligned); // Maximum value in the dataset
  const minValue = Math.min(...equityChartDataAligned); // Minimum value in the dataset
  const range = maxValue - minValue;

  // Dynamically calculate step size
  const stepSize = (() => {
    if (range <= 10) return 1;
    if (range <= 30) return 5;
    if (range <= 100) return 10;
    if (range <= 500) return 50;
    if (range <= 1000) return 100;
    if (range <= 5000) return 500;
    if (range <= 10000) return 1000;
    if (range <= 100000) return 5000;
    return Math.ceil(range / 10);
  })();

  const yAxisMin = Math.floor(minValue / stepSize) * stepSize;
  const yAxisMax = Math.ceil(maxValue / stepSize) * stepSize;
  const tickAmount = Math.ceil((yAxisMax - yAxisMin) / stepSize);

  const lineChart = {
    series: [
      {
        name: "Account A",
        data: equityChartDataAligned, // Data for dayEndEquity on each month's last date
      },
      // {
      //   name: "Account B",
      //   data: comparisonData || [500, 400], // Data for dayEndEquity on each month's last date
      // },
    ],

    options: {
      chart: {
        width: "100%",
        height: 350,
        type: "line",
        toolbar: { show: false },
      },
      legend: { show: false },
      dataLabels: { enabled: false },
      stroke: {
        width: 3,
        curve: "smooth",
        colors: ["rgba(85, 66, 246, 0.56)", "rgba(32, 201, 172, 0.56)"],
      },
      fill: {
        type: "gradient",
        gradient: {
          shadeIntensity: 1,
          opacityFrom: 0.5,
          opacityTo: 0.2,
          stops: [0, 90, 100],
          colorStops: [
            [
              {
                offset: 0,
                color: "rgba(85, 66, 246, 0.5)",
                opacity: 0.5,
              },
              {
                offset: 100,
                color: "rgba(85, 66, 246, 0.2)",
                opacity: 0.2,
              },
            ],
          ],
        },
      },
      markers: {
        size: 5,
        colors: ["rgb(85, 66, 246)", "rgb(32, 201, 172)"], // Set color for each series marker explicitly
        strokeColors: ["white", "white"],
        strokeWidth: 2,
        hover: {
          size: 7,
        },
      },
      yaxis: {
        labels: {
          style: { colors: ["#667085"] },
          formatter: (val) => `$ ${val}`,
        },
        tickAmount: tickAmount,
        min: yAxisMin,
        max: yAxisMax,
      },
      xaxis: {
        labels: {
          style: { fontSize: "12px", colors: Array(12).fill("#667085") },
        },
        categories: allMonths,
      },
      tooltip: {
        y: {
          formatter: (val) => `$ ${val}`,
        },
      },
    },
  };

  return (
    <>
      <div className="linechart">
        <div>
          <Title level={5}>Portfolio Accounts</Title>
          <p className="lastweek">
            Than last week
          </p>
        </div>
        <div style={{ display: "flex", alignItems: "center" }}>
          <span style={{ display: "flex", alignItems: "center", marginRight: "10px" }}>
            <div style={{ width: "12px", height: "12px", background: "rgba(85, 66, 246)", borderRadius: "50%", marginRight: "5px" }}></div>
            Account A
          </span>
        </div>
      </div>

      <ReactApexChart
        className="full-width"
        options={lineChart.options}
        series={lineChart.series}
        type="area"
        height={250}
        width={"100%"}
      />
    </>
  );
}

export default PortfolioAccountChart;
