import { Spin } from "antd";

const Loading = () => {
    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "85vh" }}>
            <Spin size="large" />
        </div>
    );
}

export default Loading